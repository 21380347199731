<template>
  <div>
    <div
      v-if="showModal"
      class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex"
    >
      <!--content-->
      <div class="bg-grey-lighter min-h-screen min-w-1/2 flex flex-col">
        <div class="container max-w-sm mx-auto flex-1 flex flex-col items-center justify-center px-2">
          <div class="bg-white px-6 py-8 rounded shadow-md text-black w-full">
            <h1 class="mb-8 text-3xl text-center">
              Affecter à un Utilisateur
            </h1>

            <t-rich-select
              v-model="userId"
              :fetch-options="fetchOptions"
              placeholder="Utilisateur"
              search-box-placeholder="Chercher par Username"
              value-attribute="id"
              text-attribute="username"
              :clearable="true"
              class="pb-4"
            >
              <template slot="label" slot-scope="{ option }">
                <div class="flex">
                  <div class="flex flex-col ml-2 text-gray-800">
                    <strong>{{ option.raw.username }}</strong>
                    <span class="text-sm leading-tight text-gray-700">{{ option.raw.email }}</span>
                  </div>
                </div>
              </template>
            </t-rich-select>

            <button
              class="w-full text-center py-3 rounded bg-raphal-total text-white hover:bg-raphal-termine focus:outline-none my-1"
              @click="affectToProject"
            >
              Affecter
            </button>
            <button
              class="w-full text-center py-3 rounded bg-raphal-valider text-white hover:bg-gray-500 focus:outline-none my-1"
              @click="closeForme"
            >
              Fermer
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showModal" class="opacity-25 fixed inset-0 z-40 bg-black" />
  </div>
</template>

<script>
import gql from 'graphql-tag';
import { apolloClient } from '../vue-apollo';
import { mapGetters } from 'vuex';

export default {
  name: 'Modalproject',
  components: {},
  props: [],
  data() {
    return {
      showModal: false,
      userId: '',
      id_projet: null
    };
  },
  computed: {
    ...mapGetters({
      isAuth: 'Auth/isAuth',
      user: 'Auth/user',
      roleName: 'Auth/roleUser'
    })
  },
  methods: {
    calculateDateFin: function (dateString) {
      console.log(dateString.split('T')[0]);
      let date = new Date(dateString);

      return date.toLocaleString();
    },
    toggleModal: function (id_projet) {
      this.showModal = !this.showModal;
      this.id_projet = id_projet;
    },
    async fetchOptions(q) {
      try {
        let all = false;
        if (this.roleName == 'admin') {
          all = true;
        }
        if (q == null) {
          q = '';
        }
        let { data: data } = await apolloClient.query({
          query: gql`
            query ($role: String!, $searchQyery: String!) {
              searchUser(role: $role, searchQyery: $searchQyery) {
                id
                username
                email
              }
            }
          `,
          variables: {
            role: 'user',
            searchQyery: q,
            all: all
          }
        });

        return { results: data.searchUser };
      } catch (err) {
        console.log(err);
        // eslint-disable-next-line
        Toast.fire({
          icon: 'error',
          title: err.nonFieldErrors[0].message
        });
      }
    },
    cleanForme: function () {
      this.userRow = null;

      this.projects = '';
    },
    closeForme: function () {
      this.showModal = false;
      this.cleanForme();
    },
    affectToProject: function () {
      let query = gql`
        mutation affectUserToProject($idProject: Int!, $idUser: Int!) {
          affectUserToProject(idProject: $idProject, idUser: $idUser) {
            success
          }
        }
      `;

      this.$apollo
        .mutate({
          mutation: query,
          client: 'apolloProjectClient',

          variables: {
            idUser: this.userId,
            idProject: this.id_projet
          }
        })
        .then((data) => {
          // Result
          if (data.data.affectUserToProject) {
            if (data.data.affectUserToProject.success == true) {
              // eslint-disable-next-line
              Toast.fire({
                icon: 'success',
                title: 'Opération effectuée avec succès !'
              });
              this.showModal = false;
              this.cleanForme();
            } else {
              // eslint-disable-next-line
              Toast.fire({
                icon: 'error',
                title: 'Erreur'
              });
            }
          } else {
            // eslint-disable-next-line
            Toast.fire({
              icon: 'error',
              title: 'Erreur !'
            });
          }
        })
        .catch((error) => {
          // Error
          console.error(error);
          // We restore the initial user input
        });
    }
  }
};
</script>
