<script>
export default {
  props: {
    value: {
      type: String,
      default: ''
    },
    operateur: {
      type: String,
      default: ''
    },
    valuOperateur: {
      type: String,
      default: ''
    }
  },
  computed: {
    model: {
      get() {
        return {};
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  },
  watch: {
    operateur: function () {
      let val = {
        operateur: this.operateur,
        valuOperateur: this.valuOperateur
      };
      this.model = val;
    },
    valuOperateur: function () {
      let val = {
        operateur: this.operateur,
        valuOperateur: this.valuOperateur
      };
      this.model = val;
    }
  }
};
</script>

<template>
  <div>
    <select
      v-model="operateur"
      class="w-1/4 shadow appearance-none border text-xl border-gray-1 rounded py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
    >
      <option disabled value="">
        Opérateur
      </option>
      <option>></option>
      <option>>=</option>
      <option>=</option>
      <option>&lt;=</option>
      <option>&lt;</option>
    </select>
    <input
      v-model="valuOperateur"
      class="w-2/4 shadow appearance-none border text-xl border-gray-1 rounded py-1 px-3 ml-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
      type="text"
      placeholder=""
    >
  </div>
</template>
