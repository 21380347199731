<template>
  <div class="relative h-full flex flex-col sm:justify-center items-center bg-gray-200">
    <div class="absolute flex justify-center items-center w-1/2 h-auto opacity-20">
      <svg id="svg8" class="ml-10 w-4/5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 444 444">
        <g>
          <polygon points="0 461.35 150.71 461.35 72.52 349.7 0 461.35" fill="#33a7df" />
          <path
            d="M351.5,369l78.19,111.65H279l-59.31-84.6L133,272.09H250a63.19,63.19,0,0,0,0-126.38H195.12l-60.81-87L106.66,19.33H250c104.71,0,189.54,84.9,189.54,189.61A189.14,189.14,0,0,1,351.5,369"
            transform="translate(-60.42 -19.33)"
            fill="#2a327f"
          />
        </g>
      </svg>
    </div>

    <PasswordResetRequest />
  </div>
</template>

<script>
// @ is an alias to /src
import PasswordResetRequest from '@/components/PasswordResetRequest.vue';

export default {
  name: 'PasswordResetRequestView',
  components: {
    PasswordResetRequest
  },
  computed: {
    leftImage() {
      return {
        backgroundImage: `url(/background.svg)`
      };
    }
  }
};
</script>
