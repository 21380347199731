<template>
  <div>
    <div
      v-if="showModal"
      class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex"
    >
      <!--content-->
      <div class="bg-grey-lighter min-h-screen min-w-1/2 flex flex-col">
        <div class="container max-w-sm mx-auto flex-1 flex flex-col items-center justify-center px-2">
          <div class="bg-white px-6 py-8 rounded shadow-md text-black w-full">
            <h1 class="mb-8 text-3xl text-center">
              Ajouter des Jetons
            </h1>

            <input
              v-model="jetons"
              type="text"
              class="block border border-grey-light w-full p-3 rounded mb-4"
              name="quantity"
              placeholder="Quantité"
            >

            <button
              class="w-full text-center py-3 rounded bg-raphal-total text-white hover:bg-raphal-termine focus:outline-none my-1"
              @click="addjetons"
            >
              Ajouter
            </button>
            <button
              class="w-full text-center py-3 rounded bg-raphal-valider text-white hover:bg-gray-500 focus:outline-none my-1"
              @click="closeForme"
            >
              Fermer
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showModal" class="opacity-25 fixed inset-0 z-40 bg-black" />
  </div>
</template>

<script>
import gql from 'graphql-tag';
import { mapGetters } from 'vuex';

export default {
  name: 'Modalproject',
  components: {},
  props: {
    refreshTable: { type: Function, default: null }
  },
  data() {
    return {
      showModal: false,
      jetons: '',
      userRow: null
    };
  },
  computed: {
    ...mapGetters({
      isAuth: 'Auth/isAuth',
      user: 'Auth/user',
      roleName: 'Auth/roleUser'
    })
  },
  methods: {
    toggleModal: function (userRow) {
      this.showModal = !this.showModal;
      this.userRow = userRow;
    },
    cleanForme: function () {
      this.userRow = null;

      this.jetons = '';
    },
    closeForme: function () {
      this.showModal = false;
      this.cleanForme();
    },
    addjetons: function () {
      let query = gql`
        mutation addJetons($id: Int!, $jetons: Int!) {
          addJetons(id: $id, jetons: $jetons) {
            success
            errors
          }
        }
      `;

      this.$apollo
        .mutate({
          mutation: query,

          variables: {
            id: this.userRow.id,
            jetons: this.jetons
          }
        })
        .then((data) => {
          // Result
          if (data.data.addJetons) {
            if (data.data.addJetons.success == true) {
              // eslint-disable-next-line
              Toast.fire({
                icon: 'success',
                title: 'Opération effectuée avec succès !'
              });
              this.refreshTable();
              this.showModal = false;
              this.cleanForme();
            } else {
              // eslint-disable-next-line
              Toast.fire({
                icon: 'error',
                title: JSON.parse(data.data.addJetons.errors).message
              });
            }
          } else {
            // eslint-disable-next-line
            Toast.fire({
              icon: 'error',
              title: 'Erreur !'
            });
          }
        })
        .catch((error) => {
          // Error
          console.error(error);
          // We restore the initial user input
        });
    }
  }
};
</script>
