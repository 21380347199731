<template>
  <div>
    <div
      v-if="showModal"
      class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex"
    >
      <!--content-->
      <div class="bg-grey-lighter min-h-screen min-w-1/2 flex flex-col">
        <div class="container max-w-sm mx-auto flex-1 flex flex-col items-center justify-center px-2">
          <div class="bg-white px-6 py-8 rounded shadow-md text-black w-full">
            <h1 class="mb-8 text-3xl text-center">
              Importer un Projet
            </h1>

            <modal-rich-select
              ref="projRichSelect"
              v-model="projects"
              :fetch-options="fetchOptions"
              placeholder="Projet"
              search-box-placeholder="Chercher par Nom Projet"
              value-attribute="id"
              text-attribute="nom"
              searching-text="Recherche en cours..."
              loading-more-results-text="Chargement de plus de résultats..."
              :clearable="true"
              class="pb-4"
            >
              <template slot="label" slot-scope="{ option }">
                <div class="flex">
                  <div class="flex flex-col ml-2 text-gray-800">
                    <strong>{{ option.raw.nom }}</strong>
                    <span class="text-sm leading-tight text-gray-700">{{
                      calculateDateFin(option.raw.dateDepot)
                    }}</span>
                  </div>
                </div>
              </template>
            </modal-rich-select>

            <button
              class="w-full text-center py-3 rounded bg-raphal-total text-white hover:bg-raphal-termine focus:outline-none my-1"
              @click="affectToProject"
            >
              Ajouter
            </button>
            <button
              class="w-full text-center py-3 rounded bg-raphal-valider text-white hover:bg-gray-500 focus:outline-none my-1"
              @click="closeForme"
            >
              Fermer
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showModal" class="opacity-25 fixed inset-0 z-40 bg-black" />
  </div>
</template>

<script>
import gql from 'graphql-tag';
import { apolloProjectClient } from '../vue-apollo';
import { mapGetters } from 'vuex';
import { TRichSelect } from 'vue-tailwind/dist/components';

const ModalRichSelect = {
  extends: TRichSelect,

  methods: {
    searchInputHandler(e) {
      const target = e.target;
      this.query = target.value;
      this.$parent.pageStart = 0;
    },
    listEndReached() {
      this.nextPage = this.$parent.pageStart;
      if (!this.nextPage || this.searching) {
        return;
      }
      this.filterOptions(this.query);
    }
  }
};

export default {
  name: 'Modalproject',
  components: {
    ModalRichSelect
  },
  props: [],
  data() {
    return {
      showModal: false,
      projects: '',
      userRow: null,
      projectData: null,
      pageMax: 10, // number of project displayed per page
      pageStart: 0
    };
  },
  computed: {
    ...mapGetters({
      isAuth: 'Auth/isAuth',
      user: 'Auth/user',
      roleName: 'Auth/roleUser'
    })
  },
  methods: {
    calculateDateFin: function (dateString) {
      let date = new Date(dateString);
      return date.toLocaleString();
    },
    toggleModal: function (userRow) {
      this.showModal = !this.showModal;
      this.userRow = userRow;
    },
    fetchOptions: async function (q) {
      try {
        let { data: data } = await apolloProjectClient.query({
          query: gql`
            query ($searchQyery: String!, $limit: Int, $offset: Int) {
              SearchProjects(searchQyery: $searchQyery, limit: $limit, offset: $offset) {
                id
                nom
                dateDepot
                potreePath
                premiumProject
              }
              SearchProjectsCount(searchQyery: $searchQyery)
            }
          `,
          variables: {
            searchQyery: q,
            limit: this.pageMax,
            offset: this.pageStart
          }
        });
        this.projectData = data.SearchProjects;
        this.projectCount = JSON.parse(data.SearchProjectsCount).value;
        if (this.projectCount > this.pageStart + this.pageMax) {
          this.pageStart += this.pageMax;
        } else {
          this.pageStart = 0;
        }
        this.nextPage = this.pageStart;
        return {
          results: data.SearchProjects
        };
      } catch (err) {
        console.log(err);
        // eslint-disable-next-line
        Toast.fire({
          icon: 'error',
          title: err.nonFieldErrors[0].message
        });
      }
    },
    cleanForme: function () {
      this.userRow = null;
      this.projects = '';
      this.pageStart = 0;
    },
    closeForme: function () {
      this.showModal = false;
      this.cleanForme();
    },
    affectToProject: function () {
      if (this.projects == '') {
        // eslint-disable-next-line
        Toast.fire({
          icon: 'error',
          title: 'Veuillez séléctionner un projet !'
        });
        return;
      }
      let that_ = this;
      let list = this.projectData.filter(function (proj) {
        // console.log(proj)
        return proj['id'] == that_.projects;
      });
      for (let pr of list) {
        // const $ = window.jQuery;
        const viewer = window.viewer;
        const Potree = window.Potree;
        // console.log($("#menu_tools"))
        let scene = viewer.scene;
        let urlPotree = pr.potreePath;
        if (pr.premiumProject == 1) {
          Potree.updateDrawState(true);
        } else {
          alert(
            "Le projet que vous avez importé n'est pas validé \nles boutons de dessins seront temporairement désactivés"
          );
          Potree.updateDrawState(false);
        }
        // urlPotree = urlPotree.replaceAll("raphal.fr", window.location.hostname);
        const poc = { id: pr.id, name: pr.nom, path: urlPotree, processingStatus: this.$raphalUtils.getProcessingStatus(pr) };
        Potree.loadRaphalPOC(poc, e => {
          let pointcloud = e.pointcloud;
          let material = pointcloud.material;
          material.size = 1;
          material.pointSizeType = Potree.PointSizeType.ADAPTIVE;
          material.shape = Potree.PointShape.SQUARE;
          material.activeAttributeName = 'rgba';
          scene.addPointCloud(pointcloud);
          viewer.fitToScreen();
        });
      }
      // eslint-disable-next-line
      Toast.fire({
        icon: 'success',
        title: 'Opération effectuée avec succès !'
      });
      this.showModal = false;
      this.cleanForme();
    }
  }
};
</script>
