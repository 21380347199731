<template>
  <div class="w-full h-full chart">
    <v-chart class="chart" :option="option" />
  </div>
</template>

<script>
import { use } from "echarts/core";
import { VisualMapComponent } from 'echarts/components'
import { CanvasRenderer } from "echarts/renderers";
import { BarChart, LineChart} from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  ToolboxComponent
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";

use([
  CanvasRenderer,
  BarChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  ToolboxComponent,
  LineChart,
  VisualMapComponent
]);

export default {
  name: "EchartStatsGPS",
  components: {
    VChart
  },
  props: {
    chartData: { type: [Object, Array], default: null },
    chartTitle: { type: String, default: '' },
    showInsideLabel: { type: Boolean, default: false }
  },
  provide: {
    [THEME_KEY]: "vintage"
  },
  data() {
    return {
      labelOption: {
        show: true,
        position: 'insideBottom',
        distance: 15,
        align: 'left',
        verticalAlign: 'middle',
        rotate: 90,
        fontSize: 16,
        rich: {
          name: {}
        }
      },
      option: {
        title: {
          text: this.chartTitle,
          left: "center"
        },
        toolbox: {
          show: false,
          orient: 'vertical',
          left: 'right',
          top: 'center',
          feature: {
            mark: { show: true },           
            magicType: { show: true, type: ['line'] },            
            saveAsImage: { show: true }
          }
        },
        tooltip: {
          trigger: "item",
          axisPointer: {
            type: 'cross'
          },
          formatter: (params) => {
            if (params.value.length<4) {
              return null;
            }
            return `${params.value[params.value.length-1]}`;
          }
        },
        color: ['blue', 'orange', '#99cccc', '#37A8DF', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc'],
        grid: {
          top: '12%',
          left: '1%',
          right: '10%',
          bottom: '0',
          containLabel: true
        },
        xAxis: {
          type: 'value',
          min: function (value) {
            return value.min - 20;
          },
          max: function (value) {
            return value.max +20;
          }
        },
        yAxis: {
          type: 'value',
          min: function (value) {
            return value.min -0.25;
          },
          max: function (value) {
            return value.max +0.25;
          },
          axisLabel: {
            formatter: '{value}'
          },
          axisPointer: {
            snap: true
          }
        },
        visualMap: {
          type: 'piecewise',
          show: false,
          dimension: 2,
          pieces: [{
            lte: 1,
            color: 'grey'
          },{
            gt: 1,
            lte: 3,
            color: 'red'
          },{
            gt: 3,
            color: 'green'
          }]
        },
        series: [] 
      }
    };
  },
  mounted() {
    this.getSeries();
  },
  methods: {
    getSeries () {
      const series = [];
      if (this.chartData) {
        const gpsData = this.chartData["gpsData"];
        if (Array.isArray(gpsData) && (gpsData.length > 0)) {
          const maxZ = gpsData.reduce((min, p) => p[1] > min ? p[1] : min, gpsData[0][1]) + 0.25;
          const minZ = gpsData.reduce((min, p) => p[1] < min ? p[1] : min, gpsData[0][1]) - 0.25;
          
          const gpsData2 = [...gpsData].map((dt) => {        
            return [dt[0], dt[1], -1];        
          });
          
          series.push({
            name: "Z GPS",
            type: 'line',
            data: gpsData2
          });

          const picturesData = this.chartData["picturesData"];
          if (Array.isArray(picturesData)) {
            const picturesData2 = picturesData.map((dt) => {        
              return [dt[0], maxZ, 0, dt[1]];        
            });
            series.push({
              name: "Pictures",
              type: 'line',
              data: picturesData2
            });
          }

          const gpsState = [...gpsData].map((dt) => {        
            return [dt[0], minZ, dt[2]=='RTK' ? 4 : 2, dt[2]];        
          });
          series.push({
            name: "RTK Status",
            type: 'line',
            data: gpsState
          });
        }
      }
      this.option.series = series;
    }
  }
};
</script>

<style scoped>
.chart {
  height: 350px;
}
</style>