<template>
  <main class="flex flex-col p-6">
    <modalListProjects ref="modalprojectListe" show-modal-sync="showmodal" />
    <div class="w-2/6 my-3 flex align-baseline">
      <span
        style="font-family: 'Roboto', sans-serif"
        class="min-w-full text-raphal-valider font-thin mr-5 text-6xl pt-1 align-baseline"
      >
        STATISTIQUES
      </span>
    </div>

    <div v-if="roleName != 'user' && roleName != 'supervisor'" class="flex w-full mt-6">
      <!-- <t-rich-select v-if=" userType=='user'" -->
      <t-rich-select
        v-model="userFilter"
        :fetch-options="fetchOptions"
        placeholder="Utilisateur"
        search-box-placeholder="Chercher par email ou username"
        value-attribute="id"
        text-attribute="username"
        :clearable="true"
        class="pb-4 w-1/4"
      >
        <template slot="label" slot-scope="{ option }">
          <div class="flex">
            <div class="flex flex-col ml-2 text-gray-800">
              <strong>{{ option.raw.username }}</strong>
              <span class="text-sm leading-tight text-gray-700">{{ option.raw.email }}</span>
            </div>
          </div>
        </template>
      </t-rich-select>
      <div class="w-3/4 flex mx-2">
        <div class="relative w-1/3 mx-2 cursor-pointer">
          <div
            class="flex w-full justify-center items-center h-10 m-auto bg-white rounded border-2 border-raphal-total shadow-md"
          >
            <span class="flex-grow text-center text-lg">Total des Jetons : {{ total_jetons }}</span>
          </div>
        </div>
        <div class="relative w-1/3 mx-2 cursor-pointer">
          <div
            class="flex w-full justify-center items-center h-10 m-auto bg-white rounded border-2 border-raphal-total shadow-md"
          >
            <span class="flex-grow text-center text-lg">Jetons Utilisés : {{ usedChips }} ({{ usedChipsProportion }}%)</span>
          </div>
        </div>
        <div class="relative w-1/3 mx-2 cursor-pointer">
          <div
            class="flex w-full justify-center items-center h-10 m-auto bg-white rounded border-2 border-raphal-total shadow-md"
          >
            <span class="flex-grow text-center text-lg">Jetons Restants : {{ remainingChips }} ({{ remainingChipsProportion }}%)</span>
          </div>
        </div>
      </div>
    </div>
    <div v-if="roleName != 'user' && roleName != 'supervisor'" class="flex w-full mt-6">
      <div class="flex w-1/4 flex-col">
        <div class="flex h-1/2 w-full">
          <div class="flex w-full h-full p-2 flex-col self-center justify-between">
            <div class="relative w-full py-2 cursor-pointer">
              <div
                class="flex w-full justify-start items-center h-12 bg-white rounded border-2 border-raphal-total shadow-md"
              >
                <p class="flex-grow ml-2 w-1/2 text-left text-black-1 text-lg">
                  Total :
                </p>

                <span class="flex-grow w-1/2 text-center text-lg">{{ statusProjects.total }}</span>
              </div>
            </div>
            <div class="relative w-full py-2 cursor-pointer" @click="showProjectsModal('2')">
              <div
                class="flex w-full justify-start items-center h-12 bg-white rounded border-2 border-raphal-termine shadow-md"
              >
                <p class="flex-grow ml-2 w-1/2 text-left text-black-1 text-lg">
                  Complet :
                </p>

                <span class="flex-grow w-1/2 text-center text-lg">{{ statusProjects.complet }}</span>
              </div>
            </div>
            <div class="relative w-full py-2 cursor-pointer" @click="showProjectsModal('3')">
              <div
                class="flex w-full justify-start items-center h-12 bg-white rounded border-2 border-raphal-traiter shadow-md"
              >
                <p class="flex-grow ml-2 w-1/2 text-left text-black-1 text-lg">
                  Incomplet :
                </p>

                <span class="flex-grow w-1/2 text-center text-lg">{{ statusProjects.incomplet }}</span>
              </div>
            </div>
            <div class="relative w-full py-2 cursor-pointer" @click="showProjectsModal('4')">
              <div
                class="flex w-full justify-start items-center h-12 bg-white rounded border-2 border-raphal-valider shadow-md"
              >
                <p class="flex-grow ml-2 w-1/2 text-left text-black-1 text-lg">
                  Echoué :
                </p>

                <span class="flex-grow w-1/2 text-center text-lg">{{ statusProjects.echoue }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="flex h-1/2 w-full">
          <Card class="w-full m-2 border-2 border-black-1 rounded-md">
            <header class="w-full text-left pt-2 pl-2">
              <h2 class="uppercase text-black-3">
                POURCENTAGES
              </h2>
            </header>

            <EchartPie :key="`${componentKey}-1`" class="py-2" :chart-data="qualityProjects" :height-pie="300" />
          </Card>
        </div>
      </div>

      <Card class="w-3/4 m-2 border-2 border-black-1 rounded-md">
        <header class="w-full text-left pt-5 pl-5">
          <h2 class="uppercase text-black-3">
            Statistiques journalieres
          </h2>
        </header>
        <!-- <ChartBarsTime v-bind:showSelect="true"  chart_title="Statistique journalieres / Nombre de Jours " :key="`${componentKey}-4`" /> -->
        <EchartBarTime :key="`${componentKey}-4`" :show-select="true" :search-qyery="userFilter" chart_title="" />
      </Card>
    </div>
    <div class="flex w-full mt-6">
      <Card class="w-1/3 m-2 border-2 border-black-1 rounded-md">
        <header class="w-full text-left pt-3 pl-5">
          <h2 class="uppercase text-black-3">
            ETATS AVANCEMENT DES PROJETS
          </h2>
        </header>
        <!-- <ChartBars v-bind:showSelect="true"  chart_title="Distribution des projets par Etat d'avancement"  :key="`${componentKey}-5`" v-bind:chartConfig="chart_config_georef" v-bind:chartData="chart_data_georef"/> -->
        <Echartbar
          :key="`${componentKey}-5`"
          :show-select="true"
          :show-inside-label="false"
          chart_title=""
          :chart-data="chart_data_georef"
        />
      </Card>
      <Card class="m-2 border-2 border-black-1 rounded-md w-1/3">
        <header class="w-full text-left pt-4 pl-5">
          <h2 class="uppercase text-black-3">
            précision de géoréférencement
          </h2>
        </header>
        <Echartbar
          :key="`${componentKey}-8`"
          :show-select="true"
          :show-inside-label="false"
          type="line"
          chart_title=""
          :chart-data="chart_data_emq"
        />
      </Card>

      <Card class="w-1/3 m-2 border-2 border-black-1 rounded-md">
        <header class="w-full text-left pt-4 pl-5">
          <h2 class="uppercase text-black-3">
            % Images Alignées
          </h2>
        </header>
        <EchartPie :key="`${componentKey}-6`" :chart_title="total_images" :chart-data="chart_data_images" />
      </Card>

      <!-- <Card v-if="roleName!='user'" class="w-1/3 mr-6" >
        <header class="w-full flex justify-between items-center mx-10 mt-10">
          <h2 class="uppercase text-black-3">Jetons</h2>
        </header>
        <EchartPie 
          v-bind:chart_title="total_jetons"
          :key="`${componentKey}-7`"
          v-bind:chartData="chart_data_jetons"
        />
        
      </Card> -->
    </div>

    <div v-if="roleName != 'user'" class="flex w-full mt-6">
      <Card class="w-1/3 m-2 border-2 border-black-1 rounded-md">
        <header class="w-full text-left pt-4 pl-5">
          <h2 class="uppercase text-black-3">
            Version App
          </h2>
        </header>
        <EchartPie :key="`${componentKey}-8`" chart_title="" :chart-data="chart_data_versionApp" />
      </Card>
      <Card class="w-2/3 m-2 border-2 border-black-1 rounded-md">
        <header class="w-full text-left pt-4 pl-5">
          <h2 class="uppercase text-black-3">
            Smartphones par qualité du projet
          </h2>
        </header>
        <Echartbar
          :key="`${componentKey}-4`"
          :show-select="true"
          :show-inside-label="true"
          chart_title=""
          :chart-data="chart_data_smartphone"
        />
        <!-- <ChartBars v-bind:showSelect="true"  chart_title="Smartphones par qualité du projet"  :key="`${componentKey}-4`" v-bind:chartConfig="chart_config_smartphone" v-bind:chartData="chart_data_smartphone"/> -->
      </Card>
    </div>
  </main>
</template>

<script>
import Card from '../components/Card';
import EchartPie from '@/components/EchartPie.vue';
import Echartbar from '@/components/Echartbar.vue';
import EchartBarTime from '@/components/EchartBarTime.vue';
import modalListProjects from './ModalListProjects.vue';

import gql from 'graphql-tag';
import { apolloClient } from '../vue-apollo';
import { mapGetters } from 'vuex';
export default {
  name: 'Analytics',
  components: {
    EchartPie,
    Echartbar,
    EchartBarTime,
    Card,
    modalListProjects
  },
  data() {
    return {
      userFilter: '',
      chart_data: [],
      chart_data_images: [],
      chart_data_jetons: [],
      usedChips: 0,
      usedChipsProportion: 0,
      remainingChips: 0,
      remainingChipsProportion: 0,
      total_images: '',
      total_jetons: '',
      statusProjects: { complet: 0, incomplet: 0, echoue: 0, total: 0 },
      qualityProjects: [],
      chart_data_smartphone: [],
      chart_data_georef: [],
      chart_data_versionApp: [],
      chart_data_emq: [],
      componentKey: 0
    };
  },
  computed: {
    ...mapGetters({
      isAuth: 'Auth/isAuth',
      user: 'Auth/user',
      roleName: 'Auth/roleUser'
    })
  },
  methods: {
    fotceUpdate: function () {
      this.componentKey += 1;
    },
    showProjectsModal: function (q) {
      this.$refs.modalprojectListe.toggleModal(q);
    },
    async fetchOptions(q) {
      try {
        let all = false;
        if (this.roleName == 'admin') {
          all = true;
        }
        if (q == null) {
          q = '';
        }
        let { data: data } = await apolloClient.query({
          query: gql`
            query ($role: String!, $searchQyery: String!, $all: Boolean) {
              searchUser(role: $role, searchQyery: $searchQyery, all: $all) {
                id
                username
                email
              }
            }
          `,
          variables: {
            role: 'user',
            searchQyery: q,
            all: all
          }
        });
        console.log(data);

        return { results: data.searchUser };
      } catch (err) {
        console.log(err);
        // eslint-disable-next-line
        Toast.fire({
          icon: 'error',
          title: err.nonFieldErrors[0].message
        });
      }
    }
  },
  apollo: {
    $client: 'apolloProjectClient',
    AnalyticsProjects: {
      query: gql`
        query AnalyticsProjects($searchQyery: String!) {
          AnalyticsProjects(searchQyery: $searchQyery)
        }
      `,
      fetchPolicy: 'no-cache',
      variables() {
        // Use vue reactive properties here
        return {
          searchQyery: this.userFilter ? this.userFilter : ''
        };
      },
      result({ data }) {
        let res = JSON.parse(data.AnalyticsProjects);
        this.statusProjects = res.statusProjects;
        this.qualityProjects = res.quality_projects;
        this.chart_data_images = res.images;
        this.total_images = 'Total des Images : ' + res.imagesTotale;
        this.chart_data_georef = res.statusGeoref;

        this.chart_data_emq = res.emq;
        if (this.roleName != 'user' && this.roleName != 'supervisor') {
          if (res.jetons && res.jetons.length > 0) {
            if (res.jetons[0].value) {
              this.usedChips = res.jetons[0].value;
              this.usedChipsProportion = ((this.usedChips / res.jetonsTotale) * 100).toFixed(2);
            }
            if (res.jetons[1].value) {
              this.remainingChips = res.jetons[1].value;
              this.remainingChipsProportion = ((this.remainingChips / res.jetonsTotale) * 100).toFixed(2);
            }
          }
          this.total_jetons = res.jetonsTotale;
          this.chart_data_versionApp = res.versionApp;
          this.chart_data_smartphone = res.etatSmartphones;
        }

        this.fotceUpdate();
      }
    }
  }
};
</script>
