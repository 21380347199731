<template>
  <div v-if="groupCtrl" class="flex m-2">
    <div class="w-auto">
      <div class="relative inline-block">
        <select
          v-model="selectedRule"
          class="block appearance-none bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
        >
          <option value="">
            Sélectionner un champ
          </option>
          <option
            v-for="rule in groupCtrl.rules"
            :key="rule.identifier"
            :value="rule.identifier"
            :disabled="rule.selected"
            v-text="CapitalLetters(rule.name)"
          />
        </select>
      </div>
    </div>
    <div class="w-auto flex flex-row">
      <div class="block mt-0 w-auto xs:mt-0 xs:ml-1">
        <button
          :disabled="!selectedRule"
          class="w-10 h-10 mx-1 shadow-raphal rounded-md border border-raphal-valider flex items-center bg-white text-raphal-valider cursor-pointer disabled:opacity-50 disabled:cursor-not-allowed disabled:text-raphal-valider focus:outline-none"
          @click="addRule"
        >
          <span class="text-center w-full">
            <svg
              width="10pt"
              height="20pt"
              class="w-full fill-current"
              style="enable-background: new 0 0 32 32"
              version="1.1"
              viewBox="0 0 32 32"
              xml:space="preserve"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
            >
              <path
                d="M28,14H18V4c0-1.104-0.896-2-2-2s-2,0.896-2,2v10H4c-1.104,0-2,0.896-2,2s0.896,2,2,2h10v10c0,1.104,0.896,2,2,2 s2-0.896,2-2V18h10c1.104,0,2-0.896,2-2S29.104,14,28,14z"
              />
            </svg>
          </span>
        </button>
      </div>

      <div class="block mt-0 w-auto xs:mt-0 xs:ml-1">
        <button
          :disabled="!isEmptyRules"
          class="w-10 h-10 mx-1 shadow-raphal rounded-md border border-raphal-valider flex items-center bg-white text-raphal-valider cursor-pointer disabled:opacity-50 disabled:cursor-not-allowed disabled:text-raphal-valider focus:outline-none"
          @click="_deleteFilters"
        >
          <span class="text-center w-full">
            <svg
              class="w-full fill-current"
              width="20"
              height="20pt"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 388.06 457"
            >
              <g>
                <path
                  d="M431.85,77.21c-9.51-.68-19.11-.14-29.54-.14-3-18.24-15.21-20.61-30.23-20.33-31.92.59-63.85.18-95.83.18-1.32-15-3.83-28.38-19.86-28.9-18.13-.6-19.91,14.68-21.81,28.9-32.42,0-64,.52-95.55-.23-15.25-.36-26.74,3-30.56,20.66-11.75,0-22.19-2.37-30.62.75-6.79,2.51-15.52,11.73-15.38,17.79.14,6.31,9.16,13.39,15.72,18.2,3.38,2.49,9.84,1,14.91,1q162.56,0,325.12,0c4.51,0,10.34,1.52,13.26-.7,6.9-5.21,15.93-11.43,17.64-18.75C451.55,85.16,443,78,431.85,77.21Z"
                  transform="translate(-61.97 -27.5)"
                  stroke="#000"
                  stroke-miterlimit="10"
                />
                <path
                  d="M93,254.62c7.49,63.79,14.61,127.63,22.83,191.33,3.35,25.95,16.64,37.58,41.55,37.74q98.13.63,196.26,0c24.43-.14,37.08-11.65,41.67-35.82,1.15-6.08,1.8-12.26,2.53-18.42q11.42-96.51,22.74-193c3.16-27.15,6-54.34,9-82.32H81.38C85.37,188.75,89.1,221.69,93,254.62Z"
                  transform="translate(-61.97 -27.5)"
                  stroke="#000"
                  stroke-miterlimit="10"
                />
              </g>
            </svg>
          </span>
        </button>
      </div>
      <div class="block mt-0 w-auto xs:mt-0 xs:ml-1">
        <button
          class="w-10 h-10 mx-1 shadow-raphal rounded-md border border-raphal-valider flex items-center bg-white text-raphal-valider cursor-pointer disabled:opacity-50 disabled:cursor-not-allowed disabled:text-raphal-valider focus:outline-none"
          @click="_exportProjects"
        >
          <span class="text-center w-full">
            <svg
              class="w-full"
              fill="none"
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              viewBox="0 0 24 24"
              width="20"
              height="35pt"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />
              <polyline points="7 10 12 15 17 10" />
              <line x1="12" x2="12" y1="15" y2="3" />
            </svg>
          </span>
        </button>
      </div>
      <div class="mt-2 w-auto xs:mt-0 hidden">
        <button
          class="hover:bg-blue-700 bg-blue-500 text-white font-bold py-2 px-4 rounded"
          @click="_addGroup"
          v-text="'Add Group'"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    groupCtrl: { type: Object, default: null },
    searchProjects: { type: Function, default: null },
    exportProjects: { type: Function, default: null },
    deleteFilters: { type: Function, default: null },
  },
  data() {
    return {
      selectedRule: ''
    };
  },
  computed: {
    addRuleBtnClass() {
      if (this.selectedRule) {
        return ['hover:bg-blue-700'];
      }
      return ['opacity-50', 'cursor-not-allowed'];
    },
    isEmptyRules() {
      if (this.groupCtrl && this.groupCtrl.rules.find((r) => r.selected == true)) return true;
      return false;
    }
  },
  methods: {
    addRule() {
      if (this.groupCtrl) {
        this.groupCtrl.addRule(this.selectedRule);
        let rule = this.groupCtrl.rules.find((r) => r.identifier === this.selectedRule);
        rule.selected = true;
      }
      this.selectedRule = '';
    },
    search() {
      // console.log('emit');
      this.searchProjects();
    },
    _exportProjects() {
      try {
        this.exportProjects();
      } catch (e) {
        console.log(e);
      }
    },
    _addGroup() {
      if (this.groupCtrl) {
        this.groupCtrl.newGroup();
      }
    },
    _deleteFilters() {
      try {
        this.deleteFilters();
      } catch (e) {
        console.log(e);
      }
    },
    CapitalLetters(txt) {
      return txt.charAt(0).toUpperCase() + txt.slice(1).toLowerCase();
    }
  }
};
</script>
