<template>
  <div>
    <div
      v-if="showModal"
      class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex"
    >
      <!--content-->
      <div class="bg-grey-lighter min-h-screen w-full flex flex-col">
        <div class="container w-full mx-auto flex-1 flex flex-col items-center justify-center px-2">
          <div class="bg-white  px-6 py-8 rounded shadow-md text-black w-full">
            <h1 class="mb-8 text-3xl text-center">
              Analyse statistique du signal GPS
            </h1>
            <div class="w-full grid grid-cols-2  max-h-96">
              <div class="w-full h-full">
                <EchartStatsGPS                          
                  :key="`${componentKey}-1`"  
                  chart_title="" 
                  :chart-data="gpsData"
                />
              </div>
              <div class="w-full h-full p-3 justify-center items-center flex">
                <span v-if="imagLink">
                  <img class="h-96 w-auto" :src="imagLink">
                </span>
                <span v-else>
                  <svg v-if="isTag" viewBox="0 0 512 512" class="w-full fill-current">
                    <polygon data-v-11c15836="" data-v-45080727="" points="124.43 355.25 387.57 355.25 251.05 160.3 124.43 355.25" />
                  </svg>
                  <svg v-else viewBox="0 0 512 512" class="w-full fill-current">
                    <path data-v-11c15836="" data-v-45080727="" d="M317.26,349.02l61.11,87.27h-117.79l-46.35-66.13-67.78-96.89h91.52c8.09,0,15.76-1.95,22.5-5.38,15.94-8.21,26.86-24.8,26.86-43.99,0-27.28-22.08-49.42-49.36-49.42h-42.92l-47.53-67.96-21.61-30.82h112.06c81.83,0,148.14,66.37,148.14,148.2,0,52.66-27.46,98.9-68.84,125.11" />
                  </svg>
                </span>
              </div>
            </div> 
            <div class="w-full flex items-center justify-center">
              <button 
                class="w-1/3 text-center py-3 mx-2 rounded  bg-raphal-valider text-white hover:bg-gray-500 focus:outline-none my-1"
                @click="closeDialog"
              >
                Fermer
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showModal" class="opacity-25 fixed inset-0 z-40 bg-black" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import gql from "graphql-tag";
import { apolloProjectClient } from "../vue-apollo";
import EchartStatsGPS from "./EchartStatsGPS.vue";

export default {
  name: "ModalStatsGPS",
  components: {
    EchartStatsGPS
  },
  props: [],
  data () {
    return {
      showModal: false,
      gpsData: [],
      imagLink: '',
      projectId: null,
      componentKey: 0,
    };
  },
  computed: {
    ...mapGetters({
      isAuth: "Auth/isAuth",
      user: "Auth/user",
      roleName: "Auth/roleUser"
    })
  },
  methods: {
    forceUpdate () {
      this.componentKey += 1;
    },
    toggleModal (projectId, imagLink, isTag=false) {
      this.showModal = !this.showModal;
      this.projectId = projectId;
      this.imagLink = imagLink;
      this.isTag = isTag;
      this.getGpsData();
    },
    cleanForm () {
      this.gpsData = [];
      this.projectId = null;
    },
    closeDialog () {
      this.showModal = false; 
      this.cleanForm();
    },    
    async getGpsData () {
      try {
        let {
          data: data
        } = await apolloProjectClient.query({
         query: gql`query($id: Int!) {
            adminGPSAnalytics(id: $id)
          }`,
          variables: {
            id: parseInt(this.projectId), 
          }
        });
        if (data.adminGPSAnalytics) {
          this.gpsData = JSON.parse(data.adminGPSAnalytics);
        }
        this.forceUpdate();
      } catch (err) {
        console.log(err);
        // eslint-disable-next-line
        Toast.fire({
          icon: "error",
          title: err.nonFieldErrors[0].message
        });
      }
    }
  }
};
</script>
