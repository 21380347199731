<template>
  <div>
    <div
      v-if="showModal"
      class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex"
    >
      <!--content-->
      <div class="bg-grey-lighter min-h-screen min-w-3/4 flex flex-col">
        <div class="max-w-2xl mx-auto flex-1 flex flex-col items-center justify-center px-2">
          <div class="bg-white px-6 py-8 rounded shadow-md text-black w-full">
            <h1 class="mb-8 text-3xl text-center">
              {{ operationName }}
            </h1>
            <input
              v-model="identifiant"
              type="text"
              class="block border border-grey-light w-full p-3 rounded mb-4"
              name="Identifiant"
              placeholder="Identifiant"
            >
            <div class="flex w-full">
              <div
                class="w-1/2 h-full relative mx-2 flex-1 flex flex-col items-center justify-center px-2 border border-black-1 p-3 rounded mb-4"
              >
                <span
                  class="absolute border px-2 border-gray-500 bg-white -right-1 -top-2 rounded text-gray-500 text-xs ml-2 mb-1"
                >GPS</span>
                <input
                  v-model="modelGPS"
                  type="text"
                  class="block border border-grey-light w-full p-3 rounded mb-4"
                  name="Modèle GPS"
                  placeholder="Modèle GPS"
                >
                <input
                  v-model="snGPS"
                  type="text"
                  class="block border border-grey-light w-full p-3 rounded mb-4"
                  name="SN GPS"
                  placeholder="SN GPS"
                >
              </div>
              <div
                class="w-1/2 h-full relative mx-2 flex-1 flex flex-col items-center justify-center px-2 border border-black-1 p-3 rounded mb-4"
              >
                <span
                  class="absolute border px-2 border-gray-500 bg-white -right-1 -top-2 rounded text-gray-500 text-xs ml-2 mb-1"
                >Smartphone</span>
                <input
                  v-model="modelSmartphone"
                  type="text"
                  class="block border border-grey-light w-full p-3 rounded mb-4"
                  name="Modèle Smartphone"
                  placeholder="Modèle Smartphone"
                >
                <input
                  v-model="snSmartphone"
                  type="text"
                  class="block border border-grey-light w-full p-3 rounded mb-4"
                  name="SN Smartphone"
                  placeholder="SN Smartphone"
                >
              </div>
            </div>
            <t-rich-select
              v-model="manager"
              :fetch-options="fetchOptions"
              placeholder="Manager"
              search-box-placeholder="Chercher par email ou username"
              value-attribute="id"
              text-attribute="username"
              :clearable="true"
              class="pb-4"
            >
              <template slot="label" slot-scope="{ option }">
                <div class="flex">
                  <div class="flex flex-col ml-2 text-gray-800">
                    <strong>{{ option.raw.username }}</strong>
                    <span class="text-sm leading-tight text-gray-700">{{ option.raw.email }}</span>
                  </div>
                </div>
              </template>
            </t-rich-select>

            <button
              v-if="!operationType"
              class="w-full text-center py-3 rounded bg-raphal-traiter text-white hover:bg-raphal-termine focus:outline-none my-1"
              @click="register"
            >
              Ajouter
            </button>
            <button
              v-else
              class="w-full text-center py-3 rounded bg-raphal-total text-white hover:bg-raphal-termine focus:outline-none my-1"
              @click="register"
            >
              Modifier Utilisateur
            </button>
            <button
              class="w-full text-center py-3 rounded bg-raphal-valider text-white hover:bg-gray-500 focus:outline-none my-1"
              @click="closeForme"
            >
              Fermer
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showModal" class="opacity-25 fixed inset-0 z-40 bg-black" />
  </div>
</template>

<script>
import gql from 'graphql-tag';
import { apolloClient } from '../vue-apollo';
import { mapGetters } from 'vuex';

export default {
  name: 'ModalUSer',
  components: {},
  props: {
    refreshTable: { type: Function, default: null }
  },
  data() {
    return {
      showModal: false,
      id: null,
      operationName: 'Modifier Utili',
      operationType: false,
      matosRow: null,
      identifiant: null,
      modelGPS: null,
      snGPS: null,
      modelSmartphone: null,
      snSmartphone: null,
      manager: '',
      users: [],
      isLoading: false
    };
  },
  computed: {
    ...mapGetters({
      isAuth: 'Auth/isAuth',
      user: 'Auth/user',
      roleName: 'Auth/roleUser',
      isRtk: 'Auth/isRtk'
    })
  },
  watch: {},
  methods: {
    limitText(count) {
      return `et ${count} autre utilisateurs`;
    },

    toggleModal: function (matosRow, operationName, operationType) {
      this.showModal = !this.showModal;
      this.matosRow = matosRow;

      this.operationType = operationType;
      this.operationName = operationName;
      if (this.matosRow != null) {
        this.identifiant = this.matosRow.identifiant;
        this.modelGPS = this.matosRow.gpsDevice.model;
        this.snGPS = this.matosRow.gpsDevice.serialNumber;
        this.modelSmartphone = this.matosRow.smartPhone.model;
        this.snSmartphone = this.matosRow.smartPhone.serialNumber;

        this.id = this.matosRow.id;
      } else {
        this.identifiant = null;
        this.modelGPS = null;
        this.snGPS = null;
        this.modelSmartphone = null;
        this.snSmartphone = null;
      }
    },
    async fetchOptions(q) {
      try {
        let { data: data } = await apolloClient.query({
          query: gql`
            query ($role: String!, $searchQyery: String!, $rtk: Boolean!) {
              searchUser(role: $role, searchQyery: $searchQyery, rtk: $rtk) {
                id
                username
                email
              }
            }
          `,
          fetchPolicy: 'no-cache',
          variables: {
            role: 'manager',
            searchQyery: q,
            rtk: true
          }
        });
        console.log(data);
        this.users = data.searchUser.filter((e) => e.id !== this.id);
        return { results: data.searchUser };
      } catch (err) {
        console.log(err);
        // eslint-disable-next-line
        Toast.fire({
          icon: 'error',
          title: err.nonFieldErrors[0].message
        });
      }
    },

    cleanForme: function () {
      this.operationName = 'Modifier Utili';
      this.operationType = false;
      this.matosRow = null;
      this.identifiant = null;
      this.modelGPS = null;
      this.snGPS = null;
      this.modelSmartphone = null;
      this.snSmartphone = null;
      this.manager = '';
    },
    closeForme: function () {
      this.showModal = false;
      this.cleanForme();
    },
    register: function () {
      let query = null;

      if (this.matosRow == null) {
        query = gql`
          mutation saveMatos(
            $identifiant: String!
            $modelGPS: String!
            $snGPS: String!
            $modelSmartphone: String!
            $snSmartphone: String!
            $manager: String!
          ) {
            SaveMatos(
              identifiant: $identifiant
              modelGPS: $modelGPS
              snGPS: $snGPS
              modelSmartphone: $modelSmartphone
              snSmartphone: $snSmartphone
              manager: $manager
            ) {
              success
              errors
            }
          }
        `;
      } else {
        query = gql`
          mutation updateMatos(
            $id: Int!
            $identifiant: String!
            $modelGPS: String!
            $snGPS: String!
            $modelSmartphone: String!
            $snSmartphone: String!
            $manager: String!
          ) {
            UpdateMatos(
              id: $id
              identifiant: $identifiant
              modelGPS: $modelGPS
              snGPS: $snGPS
              modelSmartphone: $modelSmartphone
              snSmartphone: $snSmartphone
              manager: $manager
            ) {
              success
              errors
            }
          }
        `;
      }

      this.$apollo
        .mutate({
          client: 'apolloProjectClient',
          mutation: query,
          variables: {
            id: this.id,
            identifiant: this.identifiant,
            modelGPS: this.modelGPS,
            snGPS: this.snGPS,
            modelSmartphone: this.modelSmartphone,
            snSmartphone: this.snSmartphone,
            manager: this.manager
          }
        })
        .then((data) => {
          // Result
          if (data.data.SaveMatos) {
            if (data.data.SaveMatos.success == true) {
              // eslint-disable-next-line
              Toast.fire({
                icon: 'success',
                title: 'Opération effectuée avec succès !'
              });
              this.showModal = false;
              this.refreshTable();
              this.cleanForme();
            } else {
              // eslint-disable-next-line
              Toast.fire({
                icon: 'error',
                title: 'error'
              });
            }
          } else if (data.data.UpdateMatos) {
            if (data.data.UpdateMatos.success == true) {
              // eslint-disable-next-line
              Toast.fire({
                icon: 'success',
                title: 'Opération effectuée avec succès !'
              });
              this.showModal = false;
              this.refreshTable();
              this.cleanForme();
            } else {
              // eslint-disable-next-line
              Toast.fire({
                icon: 'error',
                title: 'error'
              });
              // this.cleanForme()
            }
          }
        })
        .catch((error) => {
          // Error
          console.error(error);
          // We restore the initial user input
        });
    }
  }
};
</script>
<style>
.multiselect__select::before {
  @apply text-gray-1;
}
.multiselect__tags {
  @apply border-gray-1;
  height: 150px;
  max-height: 150px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.multiselect__tag,
.multiselect__option--selected.multiselect__option--highlight,
.multiselect__option--selected.multiselect__option--highlight::after {
  @apply bg-raphal-termine text-white;
}
.multiselect__tag-icon::after {
  @apply text-white;
}
.multiselect__tag-icon:focus,
.multiselect__tag-icon:hover {
  @apply bg-black-1 text-white;
}
.multiselect__content-wrapper {
  @apply border-gray-1;
}
.multiselect__option--highlight {
  @apply bg-blue-raphal  text-white;
}
.multiselect__option--highlight::after {
  @apply bg-blue-raphal  text-white;
}
.multiselect__input,
.multiselect__input:focus {
  @apply shadow-none ring-0 border-0;
}
</style>
