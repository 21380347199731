<template>
  <div ref="map-root" class="relative" style="width: 100%; height: calc(100vh - 65px - 32px)">
    <div 
      v-if="isLocationMode" 
      class="absolute flex flex-col justify-center items-center gap-1 bg-white opacity-75 rounded-lg pb-3 px-2" 
      style="top: 12px; right: 12px; z-index: 1000;"
    >
      <span class="text-sm">{{ selectedOption }} km</span>
      <input 
        v-model="selectedOption" 
        type="range" 
        min="10" 
        max="800" 
        step="10"
        class="w-full appearance-none bg-white border border-gray-400 rounded shadow"
        @input="handleChange"
      >
    </div>
    <div id="popup" class="ol-popup">
      <a id="popup-closer" href="#" class="ol-popup-closer" />
      <div id="popup-content" />
    </div>
  </div>
</template>

<script>
import View from 'ol/View';
import Map from 'ol/Map';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import Vector from 'ol/source/Vector';
import VectorLayer   from 'ol/layer/Vector';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import { transform } from 'ol/proj';
import Overlay from 'ol/Overlay';
import AnimatedCluster from 'ol-ext/layer/AnimatedCluster';
import Cluster from 'ol/source/Cluster';
import Chart from 'ol-ext/style/Chart';
import SelectCluster from 'ol-ext/interaction/SelectCluster';
import { click } from 'ol/events/condition';
import CircleHollow from 'ol/geom/Circle';

import { Circle, Fill, Stroke, Style } from 'ol/style';
import { ScaleLine } from 'ol/control';
// importing the OpenLayers stylesheet is required for having
// good looking buttons!
import 'ol/ol.css';

export default {
  name: 'MapContainer',
  components: {},
  props: {},
  data() {
    return {
      projectsData: [],
      markerSource: null,
      map: null,
      overlay: null,
      isLocationMode: false,
      selectedOption: 10,
      coordinates: null,
      bufferLayer: null,
      currentLayerSelect: null,
    };
  },
  watch: {
    projectsData: function () {
      // Set empty component's 'parentsDynamicRoutes' property on each route change
    },
    isLocationMode(newVal) {
      if (!newVal) {
        this.clearHollowZone();
        this.map.addInteraction(this.currentLayerSelect);
      } else {
        this.map.removeInteraction(this.currentLayerSelect);
      }
    }
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleOutsideClick);
  },
  mounted() {
    document.addEventListener('click', this.handleOutsideClick);
    // this is where we create the OpenLayers map
    // console.log(this)
    // let colors = ["#1d2f47","#777777","#99cccc","#37A8DF","#E8ECEB"]
    let colors = ['#777777', '#99cccc', '#37A8DF', '#E8ECEB', '#1d2f47'];
    this.map = new Map({
      // the map will be created using the 'map-root' ref
      target: this.$refs['map-root'],
      layers: [
        // adding a background tiled layer
        new TileLayer({
          source: new OSM() // tiles are served by OpenStreetMap
        })
      ],

      // the map view will initially show the whole world
      view: new View({
        zoom: 6,
        center: [322373.887, 5971050.176],
        constrainResolution: true
      })
    });

    const scaleControl = new ScaleLine({
      units: 'metric',
      bar: true,
      steps: 2,
      text: true,
      maxWidth: 120,
    });

    this.map.addControl(scaleControl);

    this.bufferLayer = new VectorLayer({
      source: new Vector(),
      style: new Style({
        fill: new Fill({
          color: 'rgba(255, 255, 255, 0.5)'
        }),
        stroke: new Stroke({
          color: '#ffcc33',
          width: 2,
        }),
      }),
    });

    this.map.addLayer(this.bufferLayer);

    this.map.on('click', (event) => {
      if (this.isLocationMode) {
        this.coordinates = event.coordinate;
        this.handleMapClick();
      }
    });
    
    // let that_= this;
    // this.map.on("click", function(e) {
    //    var coordinate = e.coordinate;
    //     that_.map.forEachFeatureAtPixel(e.pixel, function (feature) {
    //         document.getElementById('popup-content').innerHTML = '<code> <a href="/projet/'+feature.get('id_proj')+'">' + feature.get('name') + '</a></code>';
    //         that_.overlay.setPosition(coordinate);
    //     })
    // });

    if (this.projectsData) {
      var container = document.getElementById('popup');
      // var content = document.getElementById('popup-content');
      var closer = document.getElementById('popup-closer');
      this.overlay = new Overlay({
        element: container,
        autoPan: true,
        autoPanAnimation: {
          duration: 250
        }
      });
      let that_ = this;

      closer.onclick = function () {
        that_.overlay.setPosition(undefined);
        closer.blur();
        return false;
      };
      this.map.addOverlay(this.overlay);

      this.markerSource = new Vector();

      //  this.projectsData.forEach((marker) => {
      //    console.log(marker.geom)
      //     let featureProperties = {
      //        geometry: new Point(
      //           transform([marker.geom.coordinates[0], marker.geom.coordinates[1]], "EPSG:4326", "EPSG:3857")
      //        )
      //     }
      //     console.log(featureProperties.geometry)
      //     let feature = new Feature({
      //        ...featureProperties,
      //        name: marker.nom,
      //        id_proj: marker.id
      //     })
      //     this.map.on("click", function(e) {
      //        var coordinate = e.coordinate;
      //         this.map.forEachFeatureAtPixel(e.pixel, function (feature) {
      //             content.innerHTML = '<code> <a href="/projet/'+feature.get('id_proj')+'">' + feature.get('name') + '</a></code>';
      //             this.overlay.setPosition(coordinate);
      //         })
      //     });
      //     this.markerSource.addFeature(feature)
      //  });

      var styleCache = {};

      var customStyleFunction = function (feature) {
        var sel = feature.get('features');
        if (sel) {
          var type = sel[0].get('etatProjet');
          var style = styleCache[type];
          if (!style) {
            var color = colors[type];
            style = styleCache[type] = new Style({
              image: new Circle({
                fill: new Fill({
                  color: '#ffffff77'
                }),
                stroke: new Stroke({
                  color: color,
                  width: 3
                }),
                radius: 6
              })
            });
          }
          return [style];
        } else
          return [
            new Style({
              // Draw a link beetween points (or not)
              stroke: new Stroke({
                color: '#fff',
                width: 1
              })
            })
          ];
      };
      var getStyle = function (feature) {
        var features = feature.get('features');
        var size = features.length;
        // Feature style
        if (size === 1) return customStyleFunction(feature);
        // ClusterStyle
        else {
          var data = [0, 0, 0, 0, 0];
          for (let f of features) {
            data[f.get('etatProjet')]++;
          }
          // console.log(data)

          var style = styleCache[data.join(',')];
          if (!style) {
            var radius = Math.min(size + 7, 20);
            style = styleCache[data.join(',')] = new Style({
              image: new Chart({
                type: 'pie',
                radius: radius,
                data: data,
                colors: colors,
                rotateWithView: true,
                stroke: new Stroke({
                  color: 'rgba(0,0,0,0.25)',
                  width: 0.125
                })
              })
            });
          }
          return [style];
        }
      };

      var clusterSource = new Cluster({
        distance: 40,
        source: this.markerSource
      });

      // Animated cluster layer
      var clusterLayer = new AnimatedCluster({
        name: 'Projets',
        source: clusterSource,
        animationDuration: 700,
        // Cluster style
        style: getStyle
      });
      this.map.addLayer(clusterLayer);
      var selectCluster = new SelectCluster({
        // Point radius: to calculate distance between the features
        pointRadius: 20,
        animate: true,
        condition: click,
        featureStyle: customStyleFunction,
        style: null,
        selectCluster: false // disable cluster selection
      });
      this.currentLayerSelect = selectCluster;
      this.map.addInteraction(selectCluster);

      // On selected => get feature in cluster and show info
      selectCluster.getFeatures().on(['add'], function (e) {
        var c = e.element.get('features');
        if (c && c.length == 1) {
          var feature = c[0];
          document.getElementById('popup-content').innerHTML =
            '<code> <a href="/projet/' + feature.get('id_proj') + '">' + feature.get('name') + '</a></code>';
          let style = document.querySelector('.ol-popup').style;
          style.setProperty('--background-pupup', colors[feature.get('etatProjet')]);
          that_.overlay.setPosition(feature.getGeometry().getCoordinates());
        } else {
          // $(".infos").text("Cluster ("+c.length+" features)");
        }
      });

      // selectCluster.getFeatures().on(['remove'], function () {
      //     console.log("removed")
      // })
      //  this.map.getView().fit(this.markerSource.getExtent())
    }
  },
  /* destroyed() {
    // this is where we create the OpenLayers map
    console.log('unmounted');
  }, */
  methods: {
    handleOutsideClick(event) {
      const mapRoot = this.$refs["map-root"];
      const buttonLocation = this.$parent?.$refs?.multiFilter?.$refs?.buttonLocation;
      if (
        this.isLocationMode && 
        mapRoot && 
        !mapRoot.contains(event.target) &&
        (!buttonLocation || !buttonLocation.contains(event.target))
      ) {
        this.$parent.$refs.multiFilter.onClickOutsideMap();
      }
    },
    setprojectsData: function (data) {
      this.projectsData = data;
      if (!this.isLocationMode) this.markerSource.clear();
      var getType = function (proj) {
        // if(proj.premiumProject === 0){
        //   return 0;// "A Valider";
        // }else if(parseInt( proj.etatGeoref) > 1 && parseInt( proj.etatGeoref) <4){
        //   return 1; // "A Traiter";
        // }else if(parseInt( proj.etatGeoref) == 4 &&  proj.georeferenced === true){
        //   return 2;// "Archive";
        // }else {
        //   return 3; //"Autres"
        // }

        if (
          proj.premiumProject === 0 &&
          proj.etatPreTraitement < 3 &&
          proj.etatTraitement < 3 &&
          proj.etatReceptionPhoto != 0
        )
          return 0; // "A Valider";
        if (
          parseInt(proj.etatGeoref) < 4 &&
          (proj.georeferenced == false || proj.digitalised == false) &&
          proj.etatReceptionPhotos != 0
        )
          return 1; // "A Traiter";
        if (proj.georeferenced == true && proj.digitalised == true && parseInt(proj.etatGeoref) != 4) return 2; // "termine";
        if (parseInt(proj.etatGeoref) == 4) return 3;
        return 4;
      };
      this.projectsData.forEach((marker) => {
        //  console.log(marker.geom)
        let featureProperties = {
          geometry: new Point(
            transform([marker.geom.coordinates[0], marker.geom.coordinates[1]], 'EPSG:4326', 'EPSG:3857')
          )
        };
        // console.log(featureProperties.geometry)
        let feature = new Feature({
          ...featureProperties,
          name: marker.nom,
          id_proj: marker.identifiant,
          etatProjet: getType(marker)
        });

        if (!this.isLocationMode) this.markerSource.addFeature(feature);
      });

      if (this.projectsData.length > 0) {
          let ext = this.markerSource.getExtent();
          ext = [ext[0] - 1000, ext[1] - 1000, ext[2] + 1000, ext[3] + 1000];
          this.map.getView().fit(ext, { duration: 1500 });
      }
    },
    handleMapClick() {
      this.updateHollowZone();
    },
    updateHollowZone() {
      this.bufferLayer.getSource().clear();

      const radius = parseInt(this.selectedOption) * 1000;
      let circle = new CircleHollow(this.coordinates, radius);
      
      const feature = new Feature(circle);
      this.bufferLayer.getSource().addFeature(feature);
      
      this.$parent.geomFilter = {
        coords: this.coordinates,
        radius: radius
      };
      
      this.$emit('filter', 'search');
    },
    handleChange() {
      if (this.coordinates) {
        this.updateHollowZone();
      }
    },
    clearHollowZone() {
      this.coordinates = null;
      this.selectedOption = 10;
      this.bufferLayer.getSource().clear();
    },

    /**
     * Reproject a point from projection proj1 to proj2
     * coords: Array
     * projections format: "EPSG:4326", "EPSG:3857"
     **/
    reprojectPoint(coords, proj1, proj2) {
      if (!coords || !proj1 || !proj2 || !Array.isArray(coords)) {
        return false;
      }
      return new Point(transform(coords, proj1, proj2));
    },

    /**
     * Fly to new location based on its coordinate
     */
    flyToLocation(coordinate) {
      if (!coordinate) return false;

      var zoom = 15;
      var duration = 2000;
      var map = this.map;
      var view = map.getView();
      var proj2 = map.getView().getProjection().getCode();
      var point = this.reprojectPoint(coordinate, 'EPSG:4326', proj2);

      view.animate({
        center: point.getCoordinates(),
        duration: duration
      });
      view.animate(
        {
          zoom: zoom - 7,
          duration: (duration * 3) / 4
        },
        {
          zoom: zoom,
          duration: (duration * 1) / 4
        }
      );
    }
  }
};
</script>
<style>
:root {
  --background-pupup: #d11fa5;
}
.map {
  width: 100%;
  height: 400px;
}
.ol-popup {
  position: absolute;
  background-color: white;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  padding: 15px;
  border-radius: 5px;
  border: 1px solid var(--background-pupup);
  bottom: 12px;
  left: -50px;
  min-width: 100px;
}
.ol-popup:after,
.ol-popup:before {
  top: 100%;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.ol-popup:after {
  border-top-color: white;
  border-width: 10px;
  left: 48px;
  margin-left: -10px;
}
.ol-popup:before {
  border-top-color: var(--background-pupup);
  border-width: 11px;
  left: 48px;
  margin-left: -11px;
}
.ol-popup-closer {
  text-decoration: none;
  position: absolute;
  top: 2px;
  right: 8px;
}
.ol-popup-closer:after {
  content: '✖';
}

input[type="range"] {
  -webkit-appearance: none; /* Remove default styling */
  appearance: none;
  height: 4px;
  background: #ddd; /* Track background color */
  border-radius: 5px;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 15px; /* Thumb width */
  height: 15px; /* Thumb height */
  background: #4A90E2; /* Thumb color */
  border-radius: 50%;
  cursor: pointer;
}

input[type="range"]::-moz-range-thumb {
  width: 15px;
  height: 15px;
  background: #4A90E2;
  border-radius: 50%;
  cursor: pointer;
}
</style>
