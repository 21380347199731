var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"h-full w-full flex-grow"},[_c('ModalUsers',{ref:"modalformeUser",attrs:{"refresh-table":_vm.refreshTable,"show-modal-sync":"showmodal"}}),_c('Modalprojects',{ref:"modalformeprojects",attrs:{"show-modal-sync":"showmodal"}}),_c('ModalAddJetons',{ref:"modalformeAddJetons",attrs:{"refresh-table":_vm.refreshTable,"show-modal-sync":"showmodal"}}),_c('t-table',{staticClass:"admin-usr-table h-full",attrs:{"headers":_vm.getHeader,"data":_vm.paginatedProjects},scopedSlots:_vm._u([{key:"thead",fn:function(props){return [_c('thead',{class:props.theadClass},[_c('tr',{class:['h-auto md:h-10 py-2', props.trClass]},_vm._l((props.data),function(item,index){
var _obj;
return _c('th',{key:index,class:( _obj = { hidden: item.text == 'id' }, _obj[props.thClass] = true, _obj )},[_vm._v(" "+_vm._s(item.text)+" ")])}),0)])]}},{key:"row",fn:function(props){return [_c('tr',{class:['text-center h-auto md:h-8', props.trClass, props.rowIndex % 2 === 0 ? 'bg-gray-100' : '']},[_c('td',{staticClass:"hidden"},[_vm._v(" "+_vm._s(props.row.id)+" ")]),_c('td',{staticClass:"w-1/12",domProps:{"innerHTML":_vm._s(_vm.highlightText(props.row.username))}}),_c('td',{staticClass:"w-3/12",domProps:{"innerHTML":_vm._s(_vm.highlightText(props.row.name))}}),_c('td',{staticClass:"w-3/12",domProps:{"innerHTML":_vm._s(_vm.highlightText(props.row.email))}}),(_vm.isRtk === true || _vm.roleName === 'admin')?_c('td',{staticClass:"w-1/12",domProps:{"innerHTML":_vm._s(_vm.highlightText(props.row.rtkLabel))}}):_vm._e(),(_vm.role === 'manager' && (_vm.isTeria == true || _vm.roleName === 'admin'))?_c('td',{staticClass:"w-1/12",domProps:{"innerHTML":_vm._s(_vm.highlightText(props.row.teriaLabel))}}):_vm._e(),(_vm.roleName === 'admin' && (_vm.role === 'user' || _vm.role === 'supervisor'))?_c('td',{staticClass:"w-1/6",domProps:{"innerHTML":_vm._s(_vm.highlightText(props.row.managerUsername))}}):_vm._e(),(_vm.role === 'manager')?_c('td',{staticClass:"w-1/12",domProps:{"innerHTML":_vm._s(_vm.highlightText(props.row.usedJetons))}}):_vm._e(),(_vm.role === 'manager')?_c('td',{staticClass:"w-1/12",domProps:{"innerHTML":_vm._s(_vm.highlightText(props.row.remainingJetons))}}):_vm._e(),_c('td',{class:['w-1/12', 'text-center']},[_c('t-dropdown',{attrs:{"variant":"raphal"},scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
var mousedownHandler = ref.mousedownHandler;
var keydownHandler = ref.keydownHandler;
var focusHandler = ref.focusHandler;
var blurHandler = ref.blurHandler;
return _c('div',{staticClass:"flex"},[_c('button',{staticClass:"rounded px-3 py-2 bg-raphal-valider text-white hover:bg-gray-500",on:{"mousedown":mousedownHandler,"keydown":keydownHandler,"focus":focusHandler,"blur":blurHandler}},[_c('svg',{staticClass:"text-gray-1 fill-current svg-icon svg-fill",staticStyle:{"width":"20px"},attrs:{"version":"1.1","viewBox":"0 0 16 16","heigth":"20"}},[_c('path',{attrs:{"pid":"0","d":"M13 7a2 2 0 1 1 .001 3.999A2 2 0 0 1 13 7zM8 7a2 2 0 1 1 .001 3.999A2 2 0 0 1 8 7zM3 7a2 2 0 1 1 .001 3.999A2 2 0 0 1 3 7z"}})])])])}},{key:"default",fn:function(ref){
var hide = ref.hide;
return _c('div',{},[_c('button',{staticClass:"block w-full px-4 py-2 text-left text-gray-800 hover:text-white hover:bg-gray-1",on:{"click":[function($event){$event.stopPropagation();return _vm.editRow(props.row)},hide]}},[_vm._v(" Modifier ")]),_c('button',{staticClass:"block w-full px-4 py-2 text-left text-gray-800 hover:text-white hover:bg-gray-1",on:{"click":[function($event){$event.stopPropagation();return _vm.deleteUser(props.row)},hide]}},[_vm._v(" Supprimer ")]),(_vm.role != 'supervisor')?_c('button',{staticClass:"block w-full px-4 py-2 text-left text-gray-800 hover:text-white hover:bg-gray-1",on:{"click":[function($event){$event.stopPropagation();return _vm.affectUser(props.row)},hide]}},[_vm._v(" Affecter à un Projet ")]):_vm._e(),(_vm.userRole == 'admin' && _vm.role == 'manager')?_c('button',{staticClass:"block w-full px-4 py-2 text-left text-gray-800 hover:text-white hover:bg-gray-1",on:{"click":[function($event){$event.stopPropagation();return _vm.addJetons(props.row)},hide]}},[_vm._v(" Ajouter Jetons ")]):_vm._e()])}}],null,true)})],1)])]}},{key:"tfoot",fn:function(ref){
var tfootClass = ref.tfootClass;
var trClass = ref.trClass;
var tdClass = ref.tdClass;
var renderResponsive = ref.renderResponsive;
return [_c('tfoot',{class:tfootClass},[_c('tr',{class:['align-bottom', trClass]},[_c('td',{class:tdClass,attrs:{"colspan":renderResponsive ? 2 : _vm.role === 'manager' ? 7 : 6}},[_c('t-pagination',{ref:"userPagination",class:{
                'ml-auto': !renderResponsive,
                'mx-auto': renderResponsive
              },attrs:{"hide-prev-next-controls":renderResponsive,"total-items":_vm.lenUsers,"value":1,"per-page":_vm.nbrows},on:{"change":_vm.getPage}})],1)])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }