<template>
  <div class="">
    <div class="max-w-2xl md:mx-auto lg:max-w-6xl px-6 py-12">
      <div class="grid lg:grid-cols-2 gap-20">
        <div class="text-xl text-justify" style="color: rgb(103, 103, 103)">
          <div class="font-bold text-3xl pb-6" style="color: rgb(36, 40, 56)">
            Mentions légales
          </div>
          <p>Ce site ne diffuse pas de données à caractère personnel.</p>
          <br>
          <div class="font-bold text-3xl pb-6" style="color: rgb(36, 40, 56)">
            RGPD
          </div>
          <p>
            Les données transmises par les agents sont internes à l'entreprise et ne sont pas utilisées à des fins
            commerciales.
          </p>
          <br>
        </div>
        <div class="text-xl text-justify" style="color: rgb(103, 103, 103)">
          <div class="font-bold text-3xl pb-6" style="color: rgb(36, 40, 56)">
            Terms of use
          </div>
          <p>This website does not distribute personal data.</p>
          <br>
          <div class="font-bold text-3xl pb-6" style="color: rgb(36, 40, 56)">
            GDPR
          </div>
          <p>The data transmitted by the agents is for internal use and is not used for commercial purposes.</p>
          <br>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  }
};
</script>

<style></style>
