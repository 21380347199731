<script>
export default {
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  }
};
</script>

<template>
  <select v-model="model">
    <option disabled value="">
      Activité ionosphérique
    </option>
    <option>Indisponible</option>
    <option>Faible</option>
    <option>Moyen</option>
    <option>Haute</option>
  </select>
</template>
