<script>
export default {
  props: {
    value: { type: Boolean, default: true }
  },

  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  }
};
</script>

<template>
  <div>
    <input id="grille-true" v-model="model" type="radio" :value="true" :checked="true">
    <label for="grille-true" class="ml-1">Oui</label>
    <input id="grille-false" v-model="model" type="radio" class="pl-2 ml-2" :value="false">
    <label for="grille-false" class="ml-1">Non</label>
  </div>
</template>
