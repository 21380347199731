<template>
  <MainComponent :fusion-list="[]" :bookmark="true" />
</template>

<script>
// @ is an alias to /src

import MainComponent from '@/components/MainComponentPagination.vue';
// import gql from 'graphql-tag'

export default {
  name: 'AccueilPagination',
  components: {
    MainComponent
  },
  data() {
    return {
      projets: null
    };
  },
  computed: {}
};
</script>