<template>
  <div class="flex flex-col flex-wrap flex-grow">
    <app-breadcrumbs :key="componentKey" class="breadcrumb">
      <li slot-scope="{ to, label, utils }">
        <router-link :to="to" exact :itemprop="utils && utils.itemprop">
          {{ label }}
        </router-link>
      </li>

      <a slot="current" slot-scope="{ label }" class="arrows">{{ label }}</a>
    </app-breadcrumbs>
    <PotreeContainer ref="potreecontainer" :type-potree="0" :scene2="projet" :flag="flag" />
  </div>
</template>

<script>
// @ is an alias to /src

import PotreeContainer from '@/components/PotreeContainer.vue';
import gql from 'graphql-tag';
// import router from '@/router';
export default {
  name: 'PotreeView',

  components: {
    PotreeContainer
  },
  // props: ['id2', 'flag'],
  props: {
    'id2': { type: String, default: '' },
    'flag': { type: String, default: '' }
  },
  breadcrumb: {
    parent: {
      name: 'projet',
      params: {
        id: '2'
      }
    }
  },

  data() {
    return {
      componentKey2: 0,

      componentKey: 0
    };
  },
  watch: {
    projet: function () {
      console.log('modified2');
    }
  },
  methods: {
    forceRerender() {
      this.componentKey += 1;
    },
    forceRerender2() {
      this.componentKey2 += 1;
    }
  },
  apollo: {
    $client: 'apolloProjectClient',
    $subscribe: {
      onProjectSave: {
        query: gql`
          subscription {
            onProjectSave {
              projet {
                id
              }
            }
          }
        `,
        result() {
          this.$apollo.queries.projet.refetch();
        }
      }
    },
    projet: {
      // gql query
      query: gql`
        query getprojet($id: Int!) {
          projet(id: $id) {
            id
            geom
            nom
            etatTraitement
            epsg
            projectPath
            potreePath
            etatGeoref
            listImages
            premiumProject
            needGeorefUpdate
            projectState
            needGeorefUpdate
            georefpointSet {
              id
              nom
              x
              y
              z
              xProjet
              yProjet
              zProjet
              used
              nomFichier
            }
            rtk
          }
        }
      `,
      fetchPolicy: 'no-cache',
      variables() {
        // Use vue reactive properties here
        return {
          id: parseInt(this.id2)
        };
      },
      // Optional result hook
      result({ data }) {
        // this.id = data.scene.projet.id;
        // this.$route.meta.breadcrumb.label= data.projet.nom;
        if (data.projet == null) {
          this.$raphalUtils.goto('/accueil');
        }
        this.$route.meta.breadcrumb.parent.params.id = data.projet.id;
        for (let rt of this.$router.options.routes) {
          if (rt.path == '/projet/:id') {
            rt.meta.breadcrumb.label = data.projet.nom;
          }
        }
        // this.$router.options.routes[3].meta.breadcrumb.label=data.projet.nom;
        console.log(this.$router.options.routes);
        // this.$router.options.routes[2].meta.breadcrumb.parent.params.id= data.projet.id
        console.log(this.$router);

        this.forceRerender();
        this.forceRerender2();
        console.log(data.projet.projectState);
        this.$refs.potreecontainer.loadMeasurementsData(data.projet.projectState);

        const viewer = window.viewer;
        if (viewer) {
          viewer.scene.needGeorefUpdate = data.projet.needGeorefUpdate;
        }
      }
      // Static parameters
    }
  }
};
</script>
<style>
.breadcrumb {
  list-style: none;
  overflow: hidden;
  font:
    18px Helvetica,
    Arial,
    Sans-Serif;
  margin: 5px;
  padding: 0;
}
.breadcrumb li {
  float: left;
}
.breadcrumb li a {
  color: #777777;
  text-decoration: none;
  padding: 10px 10px 10px 10px;
  border-radius: 5px;
  border: 1px solid #777777;
  position: relative;
  display: block;
  float: left;
  margin-left: 30px;
  background: #ffffff;
}
.breadcrumb li a:after {
  content: '▶';
  display: block;
  width: 0px;
  left: calc(100% + 12px);
  height: 0;
  position: absolute;
  top: 19%;
  font:
    20px Helvetica,
    Arial,
    Sans-Serif;
  z-index: 2;
}

.breadcrumb li:first-child a {
  margin-left: 0px;
}
.breadcrumb li:last-child a {
  border: 1px solid #37a8df;

  color: #777777;
  pointer-events: none;
  cursor: default;
}
.breadcrumb li:last-child a:after,
.breadcrumb li:last-child a:before {
  display: none;
}

.breadcrumb li a:hover {
  background: #e7e7e7;
}
</style>
