<template>
  <div class="w-full h-full">
    <div v-if="showSelect" class="flex justify-center items-center mx-10 mt-10">
      <div class="items-center text-center m-3">
        <p>Période</p>
        <date-picker v-model="period" type="date" range placeholder="" />
      </div>
      <div class="items-center text-center m-3">
        <p />
        <button class="mt-6 mx-input" @click="clearRange">
          Tous
        </button>
      </div>
    </div>

    <v-chart ref="chartTime" class="chart" :option="option" />
  </div>
</template>

<script>
import gql from 'graphql-tag';
import { apolloProjectClient } from '../vue-apollo';
import DatePicker from 'vue2-datepicker';

import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/fr';
import { use } from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { BarChart, LineChart } from 'echarts/charts';
import { TitleComponent, TooltipComponent, LegendComponent, GridComponent, ToolboxComponent } from 'echarts/components';
import VChart, { THEME_KEY } from 'vue-echarts';

use([
  CanvasRenderer,
  BarChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  ToolboxComponent,
  LineChart
]);

export default {
  name: 'EchartBar',
  components: {
    VChart,
    DatePicker
  },
  props: {
    chartTitle: { type: String, default: '' },
    showSelect: { type: Boolean, default: false },
    showInsideLabel: { type: Boolean, default: false },
    searchQyery: { type: String, default: '' }
  },
  provide: {
    [THEME_KEY]: 'vintage'
  },
  data() {
    return {
      height: '600px',
      day: null,
      week: null,
      month: null,
      year: null,
      period: null,
      start: null,
      end: null,
      chartData: {
        dataCount: [],
        xAxisData: []
      },
      labelOption: {
        show: true,
        position: 'insideBottom',
        distance: 15,
        align: 'left',
        verticalAlign: 'middle',
        rotate: 90,
        formatter: this.showInsideLabel == true ? '{c}  {name|{a}}' : '',
        fontSize: 16,
        rich: {
          name: {}
        }
      },
      option: {
        title: {
          text: this.chartTitle,
          left: 'center'
        },
        toolbox: {
          show: true,
          orient: 'vertical',
          left: 'right',
          top: 'center',
          feature: {
            mark: { show: true },

            magicType: { show: true, type: ['line', 'bar'] },

            saveAsImage: { show: true }
          }
        },
        tooltip: {
          trigger: 'item',
          formatter: '{c}',
          axisPointer: {
            type: 'shadow',
            label: {
              show: true
            }
          }
        },
        color: ['#1d2f47', '#777777', '#99cccc', '#37A8DF', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc'],
        grid: {
          top: '12%',
          left: '1%',
          right: '10%',
          bottom: '10%',

          containLabel: true
        },
        xAxis: [
          {
            type: 'category'
          }
        ],
        yAxis: [
          {
            type: 'value'
          }
        ],
        series: []
      }
    };
  },
  watch: {
    day: function () {
      let mm = new Date(this.day);
      this.start = mm.getFullYear() + '-' + (mm.getMonth() + 1) + '-' + mm.getDate();
      this.end = this.start;
      this.getdata();
    },
    week: function () {
      let mm = new Date(this.week);
      let endmm = new Date(this.week);
      endmm.setDate(mm.getDate() + 7);
      this.start = mm.getFullYear() + '-' + (mm.getMonth() + 1) + '-' + mm.getDate();
      this.end = endmm.getFullYear() + '-' + (endmm.getMonth() + 1) + '-' + endmm.getDate();
      this.getdata();
    },
    year: function () {
      let mm = new Date(this.year);
      let endmm = new Date(this.year);
      endmm.setFullYear(mm.getFullYear() + 1);
      this.start = mm.getFullYear() + '-' + (mm.getMonth() + 1) + '-' + mm.getDate();
      this.end = endmm.getFullYear() + '-' + (endmm.getMonth() + 1) + '-' + endmm.getDate();
      this.getdata();
    },
    month: function () {
      let mm = new Date(this.month);
      let endmm = new Date(this.month);
      endmm.setMonth(mm.getMonth() + 1);
      this.start = mm.getFullYear() + '-' + (mm.getMonth() + 1) + '-' + mm.getDate();
      this.end = endmm.getFullYear() + '-' + (endmm.getMonth() + 1) + '-' + endmm.getDate();
      this.getdata();
    },
    period: function () {
      let mm = new Date(this.period[0]);
      let endmm = new Date(this.period[1]);
      // endmm.setMonth(mm.getMonth()+1)
      this.start = mm.getFullYear() + '-' + (mm.getMonth() + 1) + '-' + mm.getDate();
      this.end = endmm.getFullYear() + '-' + (endmm.getMonth() + 1) + '-' + endmm.getDate();
      this.getdata();
    }
  },
  mounted() {
    this.clearRange();
  },
  methods: {
    clearRange: function () {
      this.start = null;
      this.end = null;
      this.getdata();
    },
    getSeries: function () {
      let series = [];
      for (var k in this.chartData) {
        if (k != 'xAxisData') {
          series.push({
            name: k,
            type: 'line',
            barGap: 0,
            label: this.labelOption,
            emphasis: {
              focus: 'series'
            },
            data: this.chartData[k]
          });
        }
      }
      this.option.xAxis = this.chartData.xAxisData;
      if (this.$refs.chartTime) {
        this.$refs.chartTime.setOption({
          xAxis: [
            {
              type: 'category',
              data: this.chartData.xAxisData
            }
          ]
        });
      }

      this.option.series = series;
    },
    async getdata() {
      try {
        let { data: data } = await apolloProjectClient.query({
          query: gql`
            query ($searchQyery: String, $start: String, $end: String) {
              ProjectsStatsDaily(searchQyery: $searchQyery, start: $start, end: $end)
            }
          `,
          fetchPolicy: 'no-cache',
          variables: {
            searchQyery: this.searchQyery ? this.searchQyery : '',
            start: this.start,
            end: this.end
          }
        });
        console.log(JSON.parse(data.ProjectsStatsDaily));
        this.chartData = JSON.parse(data.ProjectsStatsDaily);
        this.getSeries();
      } catch (err) {
        console.log(err);
        // eslint-disable-next-line
        Toast.fire({
          icon: 'error',
          title: err.nonFieldErrors[0].message
        });
      }
    }
  }
};
</script>

<style scoped>
.chart {
  height: 500px;
}

.mx-datepicker {
  position: relative;
  display: inline-block;
  width: 210px;
}
</style>
