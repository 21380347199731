<template>
  <div class="h-full w-full flex flex-col">
    <div class="flex flex-col">
      <div class="w-3/6 px-6 pt-6 my-3 flex align-baseline">
        <span
          style="font-family: 'Roboto', sans-serif"
          class="min-w-full w-full text-raphal-valider font-thin mr-5 text-6xl pt-1 align-baseline uppercase"
        >
          GESTION MATeriel
        </span>
      </div>
    </div>
    <div class="flex flex-row flex-grow">
      <div class="h-full flex md:w-full xl:w-full px-6">
        <t-card variant="raphal" class="mb-5 flex flex-col flex-grow">
          <template v-slot:header>
            <div class="items-center text-xl font-bold mx-auto h-8">
              Liste Matériel
              <div class="float-right block text-base">
                <t-button type="button" variant="raphal" @click="addUser()">
                  Ajouter
                </t-button>
              </div>
            </div>
          </template>
          <PackMaterielManagement ref="matosTable" :nbrows="10" />
        </t-card>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import PackMaterielManagement from '@/components/PackMaterielManagement.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'UsersView',
  components: {
    PackMaterielManagement
  },
  computed: {
    ...mapGetters({
      isAuth: 'Auth/isAuth',
      user: 'Auth/user',
      role: 'Auth/roleUser'
    })
  },

  methods: {
    addUser() {
      this.$refs.matosTable.$refs.modalMatosForm.toggleModal(null, 'Ajouter Manager', false);
      this.showmodal = true;
    }
  }
};
</script>
