<script>
export default {
  props: {
    rules: { type: Array, default: null },
    ruleCtrl: { type: Object, default: null }
  },
  computed: {
    ruleName() {
      return this.rules.find((r) => r.identifier === this.ruleCtrl.ruleIdentifier).name;
    },
    borderClass() {
      return (
        this.ruleCtrl && this.ruleCtrl.ruleComponent && 
        this.ruleCtrl.ruleComponent.props && this.ruleCtrl.ruleComponent.props.value && 
        this.ruleCtrl.ruleComponent.props.value.type.name === 'Boolean'
      ) ? "" : "border";
    }
  }
};
</script>

<template>
  <div class="flex flex-row w-full">
    <div class="w-1/4 text-gray-700 text-sm font-bold ml-3 my-auto inline-block align-middle">
      {{ ruleName }}
    </div>
    <div class="mx-6 w-3/4">
      <component
        :is="ruleCtrl.ruleComponent"
        :class="['w-full shadow appearance-none text-xl border-gray-1 rounded py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline', borderClass]"
        :value="ruleCtrl.ruleData"
        @input="ruleCtrl.updateRuleData"
      />
    </div>
  </div>
</template>
