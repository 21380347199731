<template>
  <div class="relative w-80 rounded-md px-6 py-4 bg-gray-200 border border-raphal-valider shadow-lg">
    <form action method="post" @submit.prevent="login">
      <div class="mb-4">
        <label for="password1">Mot de passe</label>
        <t-input v-model="password1" name="password1" type="password" placeholder="Mot de passe" />
      </div>
      <div class="mb-4">
        <label for="password2">Confirmation du mot de passe</label>
        <t-input v-model="password2" name="password2" type="password" placeholder="Confirmation du mot de passe" />
      </div>

      <div class="flex w-full items-center justify-items-center justify-center">
        <button style="background: #37a8df" class="p-2 border rounded-md text-white focus:outline-none" type="submit">
          Envoyer
        </button>
        <!-- <a
            class="inline-block align-baseline font-bold text-sm text-blue hover:text-blue-darker"
          >Mot de passe oublié ?</a> -->
      </div>
    </form>
  </div>
</template>

<script>
import gql from 'graphql-tag';

export default {
  name: 'PasswordReset',
  props: {
    token: { type: String, default: '' }
  },
  data() {
    return {
      password1: null,
      password2: null
    };
  },
  methods: {
    async login() {
      if (this.password1 != this.password2) {
        // eslint-disable-next-line
        Toast.fire({
          icon: 'error',
          title: 'Les mots de passe ne correspondent pas !'
        });
      }

      this.$apollo
        .mutate({
          // Query
          mutation: gql`
            mutation updatePasswordMutation($token: String!, $password1: String!, $password2: String!) {
              updatePasswordMutation(token: $token, password1: $password1, password2: $password2) {
                success
                errors
              }
            }
          `,
          // Parameters
          variables: {
            token: this.token,
            password1: this.password1,
            password2: this.password2
          }
        })
        .then((data) => {
          // Result
          console.log(data);
          if (data.data.updatePasswordMutation.success == true) {
            // eslint-disable-next-line
            Toast.fire({
              icon: 'success',
              title: 'Opération effectuée avec succès !'
            });
            this.$raphalUtils.goto('/login');
          } else {
            // eslint-disable-next-line
            Toast.fire({
              icon: 'error',
              title: JSON.parse(data.data.updatePasswordMutation.errors).message
            });
          }
        })
        .catch((error) => {
          // Error
          console.error(error);
          // We restore the initial user input
        });
    }
  }
};
</script>
