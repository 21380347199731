<template>
  <v-chart :option="option" autoresize />
</template>

<script>
import { use } from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { GaugeChart } from 'echarts/charts';
import { TitleComponent, TooltipComponent, LegendComponent, ToolboxComponent } from 'echarts/components';
import VChart, { THEME_KEY } from 'vue-echarts';

use([CanvasRenderer, GaugeChart, TitleComponent, TooltipComponent, LegendComponent, ToolboxComponent]);

export default {
  name: 'EchartGauge',
  components: {
    VChart
  },
  props: {
    score: { type: Number, default: null },
    small: { type: Boolean, default: false }
  },
  provide: {
    [THEME_KEY]: 'vintage'
  },
  data() {
    return {
      option: {
        series: [
          {
            type: 'gauge',
            startAngle: 180,
            endAngle: 0,
            min: 0,
            max: 100,
            splitNumber: 4,
            itemStyle: {
              color: '#777777',
              shadowColor: '#777777',
              shadowBlur: 2,
              shadowOffsetX: 0,
              shadowOffsetY: 0
            },
            progress: {
              show: true,
              roundCap: true,
              width: this.small == true ? 3 : 7
            },
            pointer: {
              icon: 'path://0999 2092.40383,616.194028 2092.44859,617.312956 L2096.90698,728.755929 C2097.05155,732.369577 2094.2393,735.416212 2090.62566,735.56078 C2090.53845,735.564269 2090.45117,735.566014 2090.36389,735.566014 L2090.36389,735.566014 C2086.74736,735.566014 2083.81557,732.63423 2083.81557,729.017692 C2083.81557,728.930412 2083.81732,728.84314 2083.82081,728.755929 L2088.2792,617.312956 C2088.32396,616.194028 2089.24407,615.30999 2090.36389,615.30999 Z',
              length: '75%',
              width: this.small == true ? 5 : 8,
              offsetCenter: [0, '5%']
            },
            axisLine: {
              roundCap: true,
              lineStyle: {
                width: 5
              }
            },
            axisTick: {
              show: false,
              distance: 5,

              splitNumber: 2,
              lineStyle: {
                width: 1,
                color: '#999'
              }
            },
            splitLine: {
              show: false,
              distance: 5,
              length: 5,
              lineStyle: {
                width: 3,
                color: '#999'
              }
            },
            axisLabel: {
              show: false
            },
            title: {
              show: false
            },
            detail: {
              backgroundColor: '#fff',
              borderColor: '#999',
              borderWidth: 0,
              width: '60%',
              lineHeight: this.small == true ? 20 : 15,
              height: this.small == true ? 20 : 10,
              borderRadius: 8,
              offsetCenter: [0, this.small == true ? '100%' : '60%'],
              valueAnimation: true,
              formatter: function (value) {
                return '{value|' + value.toFixed(0) + '}{unit|%}';
              },
              rich: {
                value: {
                  fontSize: this.small == true ? 8 : 15,
                  fontWeight: 'bolder',
                  color: '#777'
                },
                unit: {
                  fontSize: this.small == true ? 8 : 15,
                  color: '#999',
                  padding: [0, 0, 0, 0]
                }
              }
            },
            data: [
              {
                value: this.score
              }
            ]
          }
        ]
      }
    };
  }
};
</script>

<style scoped>
.chart {
  height: 420px;
}
</style>
