<template>
  <div class="relative h-full flex flex-col sm:justify-center items-center bg-gray-200">
    <div class="absolute flex justify-center items-center w-1/2 h-auto opacity-20">
      <svg id="svg8" class="ml-10 w-4/5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 444 444">
        <g>
          <polygon points="0 461.35 150.71 461.35 72.52 349.7 0 461.35" fill="#33a7df" />
          <path
            d="M351.5,369l78.19,111.65H279l-59.31-84.6L133,272.09H250a63.19,63.19,0,0,0,0-126.38H195.12l-60.81-87L106.66,19.33H250c104.71,0,189.54,84.9,189.54,189.61A189.14,189.14,0,0,1,351.5,369"
            transform="translate(-60.42 -19.33)"
            fill="#2a327f"
          />
        </g>
      </svg>
    </div>
    <div class="grid gap-2 grid-cols-3 bg-white p-2 z-10 border rounded-md">
      <span>Envoyer Projet</span>
      <UploadFile v-if="roleName == 'admin'" :type-upload="0" :utilisateur="utilisateur" />
      <t-rich-select
        v-model="utilisateur"
        :fetch-options="fetchOptions"
        placeholder="Utilisateur"
        search-box-placeholder="Chercher par email ou username"
        value-attribute="id"
        text-attribute="username"
        :clearable="true"
        class="pb-4"
      >
        <template slot="label" slot-scope="{ option }">
          <div class="flex">
            <div class="flex flex-col ml-2 text-gray-800">
              <strong>{{ option.raw.username }}</strong>
              <span class="text-sm leading-tight text-gray-700">{{ option.raw.email }}</span>
            </div>
          </div>
        </template>
      </t-rich-select>
      <span>Mettre à jour Projet</span>
      <UploadFile v-if="roleName == 'admin'" :type-upload="1" :utilisateur="utilisateurReupload" />
      <t-rich-select
        v-model="utilisateurReupload"
        :fetch-options="fetchOptions"
        placeholder="Utilisateur"
        search-box-placeholder="Chercher par email ou username"
        value-attribute="id"
        text-attribute="username"
        :clearable="true"
        class="pb-4"
      >
        <template slot="label" slot-scope="{ option }">
          <div class="flex">
            <div class="flex flex-col ml-2 text-gray-800">
              <strong>{{ option.raw.username }}</strong>
              <span class="text-sm leading-tight text-gray-700">{{ option.raw.email }}</span>
            </div>
          </div>
        </template>
      </t-rich-select>
      <span class="my-auto">Sans Prétraitement</span>
      <button
        class="w-full text-center py-3 rounded bg-raphal-traiter text-white hover:bg-raphal-termine focus:outline-none my-1"
        @click="download('csvPretraitement')"
      >
        Exporter
      </button>
      <div />
      <span class="my-auto">Sans Calcul 3D</span>
      <button
        class="w-full text-center py-3 rounded bg-raphal-traiter text-white hover:bg-raphal-termine focus:outline-none my-1"
        @click="download('csvCalcul3D')"
      >
        Exporter
      </button>
      <div />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import UploadFile from '@/components/UploadFile.vue';
import { mapGetters } from 'vuex';
import gql from 'graphql-tag';
import { apolloClient } from '../vue-apollo';
export default {
  name: 'Reupload',
  components: {
    UploadFile
  },
  data() {
    return {
      utilisateur: '',
      utilisateurReupload: ''
    };
  },
  computed: {
    ...mapGetters({
      roleName: 'Auth/roleUser'
    })
  },
  methods: {
    download(type) {
      let link = document.createElement('a');
      link.setAttribute('download', 'LAZ_RAPHAL_' + this.nomProjet + '.' + type);
      link.href =
        // "http://"+window.location.hostname+"/api/api_raphal/download/" +
        window.location.origin + '/api/api_raphal/download/' + type;
      document.body.appendChild(link);
      link.click();
    },
    async fetchOptions(q) {
      try {
        let all = true;

        let { data: data } = await apolloClient.query({
          query: gql`
            query ($role: String!, $searchQyery: String!, $all: Boolean!) {
              searchUser(role: $role, searchQyery: $searchQyery, all: $all) {
                id
                username
                email
              }
            }
          `,
          variables: {
            role: 'user',
            searchQyery: q,
            all: all
          }
        });

        return { results: data.searchUser };
      } catch (err) {
        console.log(err);
        // eslint-disable-next-line
        Toast.fire({
          icon: 'error',
          title: err.nonFieldErrors[0].message
        });
      }
    }
  }
};
</script>
