<template>
  <div>
    <div
      v-if="showModal"
      class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex"
    >
      <!--content-->
      <div class="bg-grey-lighter min-h-screen min-w-1/2 flex flex-col">
        <div class="container max-w-sm mx-auto flex-1 flex flex-col items-center justify-center px-2">
          <div
            class="w-full lg:flex flex-col px-6 pt-8 mb-1 border-solid border-2 rounded-md border-black-1 bg-white"
            style="width: 550px"
          >
            <h1 class="mb-8 text-3xl text-center">
              Import Fichier de Géoréférencement
            </h1>
            <div class="w-full h-full flex flex-row p-2">
              <div class="h-full w-full flex mx-2 flex-col relative font-bold">
                <span class="absolute border-2 px-2 bg-white left-3 -top-3 rounded text-gray-500 text-xs ml-2 mt-1">Délimiteur</span>

                <div class="h-full w-full flex-col border-2 rounded-md p-3 flex">
                  <div>
                    <t-radio id="one" v-model="seperator" type="radio" value=" " />
                    <label class="ml-2" for="one">Espace</label>
                  </div>
                  <div>
                    <t-radio id="one" v-model="seperator" type="radio" value=";" />
                    <label class="ml-2" for="one">;</label>
                  </div>
                  <div>
                    <t-radio id="one" v-model="seperator" type="radio" value="," />
                    <label class="ml-2" for="one">,</label>
                  </div>
                  <div>
                    <t-radio id="one" v-model="seperator" type="radio" value="." />
                    <label class="ml-2" for="one">.</label>
                  </div>
                  <div>
                    <t-radio id="one" v-model="seperator" type="radio" value="*#*" />
                    <label class="ml-2" for="one">Tab</label>
                  </div>
                  <div>
                    <t-radio id="one" v-model="seperator" type="radio" value="autres" />
                    <label class="ml-2" for="one">Autre</label>
                    <input
                      v-model="otherseperator"
                      type="text"
                      class="border border-grey-light w-1/2 p-1 rounded ml-2"
                      name="otherSpeparator"
                      placeholder=""
                      :disabled="seperator != 'autres'"
                    >
                  </div>
                </div>
                <label class="flex items-center mt-1">
                  <t-checkbox v-model="withHeader" name="options" value="true" checked />
                  <span class="ml-2 text-sm">Avec entête</span>
                </label>
              </div>
              <div class="h-full w-full flex mx-2 flex-col relative font-bold">
                <span class="absolute border-2 px-2 bg-white left-3 -top-3 rounded text-gray-500 text-xs ml-2 mt-1">Ordre colonnes</span>

                <div class="h-full w-full flex-col border-2 rounded-md p-3 justify-center items-center flex">
                  <div class="my-0 pl-1 w-full">
                    <div class="my-1 w-full flex font-bold justify-center items-center">
                      <span class="w-1/2"> Nom </span>
                      <span class="w-1/2">
                        <input
                          v-model="nomOrder"
                          type="text"
                          class="border border-grey-light w-1/2 p-1 rounded ml-2"
                          name="headerName"
                          placeholder=""
                        >
                      </span>
                    </div>

                    <div class="text-base w-full justify-center items-center">
                      <div class="my-1 w-full flex font-bold justify-center items-center">
                        <span class="w-1/2"> X </span>
                        <span class="w-1/2">
                          <input
                            v-model="XOrder"
                            type="text"
                            class="border border-grey-light w-1/2 p-1 rounded ml-2"
                            name="headerX"
                            placeholder=""
                          >
                        </span>
                      </div>
                      <div class="my-1 w-full flex justify-center items-center">
                        <span class="w-1/2"> Y </span>
                        <span class="w-1/2">
                          <input
                            v-model="YOrder"
                            type="text"
                            class="border border-grey-light w-1/2 p-1 rounded ml-2"
                            name="headerY"
                            placeholder=""
                          >
                        </span>
                      </div>
                      <div class="w-full flex justify-center items-center">
                        <span class="w-1/2"> Z </span>
                        <span class="w-1/2">
                          <input
                            v-model="ZOrder"
                            type="text"
                            class="border border-grey-light w-1/2 p-1 rounded ml-2"
                            name="headerZ"
                            placeholder=""
                          >
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="w-full flex flex-row items-center justify-center p-2">
              <div v-if="dataFileValid == true" style="display: flex; justify-content: space-evenly">
                <div class="w-full">
                  <div class="w-full">
                    <table
                      style="width: 500px"
                      class="shadow-inner w-full border-collapse border table_coords fixed_header"
                    >
                      <thead>
                        <tr>
                          <th scope="col">
                            Nom
                          </th>
                          <th scope="col">
                            X (m)
                          </th>
                          <th scope="col">
                            Y (m)
                          </th>
                          <th scope="col">
                            Z (m)
                          </th>
                        </tr>
                      </thead>
                      <tbody class="scrollbar-hide">
                        <tr v-for="item in dataPreview" :key="item.id">
                          <td>{{ item.nom }}</td>
                          <td>{{ item.x }}</td>
                          <td>{{ item.y }}</td>
                          <td>{{ item.z }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div v-else>
                <span class="scrollData scrollbar-hide">
                  <div v-for="item in dataPreview" :key="item.id">
                    {{ item.replaceAll('*#*', '\t') }}
                  </div>
                </span>
              </div>
            </div>
            <div class="w-full flex flex-row items-center justify-center p-2">
              <button
                :disabled="dataFileValid == false"
                class="w-1/2 text-center mx-3 py-3 rounded bg-raphal-total text-white hover:bg-raphal-termine focus:outline-none my-1"
                @click="saveData"
              >
                Valider
              </button>
              <button
                class="w-1/2 text-center mx-3 py-3 rounded bg-raphal-valider text-white hover:bg-gray-500 focus:outline-none my-1"
                @click="closeForme"
              >
                Annuler
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showModal" class="opacity-25 fixed inset-0 z-40 bg-black" />
  </div>
</template>

<script>
export default {
  name: 'Modalimport',
  components: {},
  props: {
    refreshTable: { type: Function, default: null }
  },
  data() {
    return {
      showModal: false,
      fileName: '',
      dataFile: '',
      dataFileValid: false,
      dataPreview: '',
      seperator: ' ',
      otherseperator: null,
      currentsparator: ' ',
      nomOrder: 1,
      XOrder: 2,
      YOrder: 3,
      ZOrder: 4,
      withHeader: false
    };
  },
  watch: {
    seperator: function () {
      this.currentsparator = this.seperator;
      this.parseData();
    },
    otherseperator: function () {
      this.currentsparator = this.otherseperator;
      this.parseData();
    },
    nomOrder: function () {
      this.parseData();
    },
    XOrder: function () {
      this.parseData();
    },
    YOrder: function () {
      this.parseData();
    },
    ZOrder: function () {
      this.parseData();
    },
    withHeader: function () {
      this.parseData();
    }
  },
  methods: {
    toggleModal: function (dataFile, fileName) {
      this.showModal = !this.showModal;
      this.dataFile = dataFile;
      this.fileName = fileName;
      this.parseData();
    },
    cleanForme: function () {
      this.dataFile = '';
    },
    closeForme: function () {
      this.showModal = false;
      this.cleanForme();
    },
    addDataFile: function (dataFile) {
      this.dataFile = dataFile;
    },
    parseData: function () {
      let startLine = 0;
      if (this.withHeader == 'true') {
        startLine++;
      }
      lines = this.dataFile.replaceAll('\r', '');
      lines = lines.replaceAll('\t', '*#*');
      var lines = lines.split('\n');

      this.dataFileValid = true;
      for (var i = startLine; i < lines.length; i++) {
        var line2 = lines[i].split(this.currentsparator);
        if (line2 == '') {
          continue;
        }

        if (line2.length < 4) {
          this.dataFileValid = false;
          break;
        }
      }

      if (this.dataFileValid == false) {
        this.dataPreview = [];
        for (let line of lines) {
          this.dataPreview.push(line);
        }
      } else {
        this.dataPreview = [];
        for (var j = startLine; j < lines.length; j++) {
          const line = lines[j].split(this.currentsparator).map(i => i.trim()),
                n = line.length;
          const kx = parseInt(this.XOrder)-1,
                ky = parseInt(this.YOrder)-1,
                kz = parseInt(this.ZOrder)-1,
                kn = parseInt(this.nomOrder)-1;
          if ((kx > n) || (ky > n) || (kz > n)) {
            continue;
          }
          // const [a, b, c, ...names] = line.reverse();
          // const pos = [a, b, c];
          // const nom = (names.reverse()).join(' ').replaceAll(/"/g, '');
          const nom = line[kn];
          const x = line[kx] ? parseFloat(line[kx]) : 0;
          const y = line[ky] ? parseFloat(line[ky]) : 0;
          const z = line[kz] ? parseFloat(line[kz]) : 0;
          this.dataPreview.push({
            nom: nom,
            x: x,
            y: y,
            z: z
          });
        }
      }
    },
    saveData: function () {
      const $ = window.jQuery;
      let content_table = '';
      let list_table = '';
      var find_doubles = false;
      // let elBackground = $(`#background_options`);

      for (let line of this.dataPreview) {
        let cnt = $('#select_point option').filter(function () {
          return this.value.startsWith(line.nom);
        }).length;
        let nom = line.nom;
        if (cnt > 0) {
          find_doubles = true;
          nom = line.nom + '_' + cnt;
        }
        if (
          isNaN(parseFloat(line.z).toFixed(3)) ||
          isNaN(parseFloat(line.x).toFixed(3)) ||
          isNaN(parseFloat(line.y).toFixed(3)) ||
          nom.toLowerCase().startsWith('#id')
        ) {
          continue;
        }
        content_table += `<tr class="hidden_row">
							<td>
							<div class="custom-control custom-checkbox">
								<input type="checkbox" name="chk_table" class="custom-control-input" id="customCheck_${nom}" >
								<label class="custom-control-label" for="customCheck_${nom}"></label>
							</div>
							</td>
							<td>${nom}</td>
							<td>${parseFloat(line.x).toFixed(3)}</td> 
							<td>${parseFloat(line.y).toFixed(3)}</td>
							<td>${parseFloat(line.z).toFixed(3)}</td>
							<td></td>
							<td class="hidden">0</td>
							<td class="hidden">${this.fileName}</td>
							</tr>`;
        list_table += `<option value="${nom}">${nom}</option>`;
      }
      $(`#coords_table`).append(`
        ${content_table}
      `);
      this.closeForme();
      if (find_doubles == true) {
        alert(
          'Attention ! Si un point de ce second import de fichier a le même nom qu’un point du premier import, il sera renommé X_1, X_2, etc.'
        );
      }
      // let list_coords = $('#select_point');
      $('#select_point').append(`
        ${list_table}
      `);

      $('input[name="chk_table"]').on('change', function () {
        for (let dessin of window.viewer.scene.dessinsTransform) {
          if (dessin.name == this.id.replace('customCheck_', '')) {
            dessin.use_it = this.checked;
            let ligne_table = $('#' + this.id).closest('tr');
            if (this.checked) {
              dessin.visible = true;
              ligne_table.removeClass('table-danger').addClass('table-success');
              ligne_table[0].cells[6].innerText = 1;
            } else {
              dessin.visible = false;
              ligne_table.removeClass('table-danger').addClass('table-success');
              ligne_table[0].cells[6].innerText = 0;
            }
          }
        }
      });
    }
  }
};
</script>

<style scoped>
.fixed_header {
  table-layout: fixed;
  border-collapse: collapse;
}

.fixed_header tbody {
  display: block;
  width: 100%;
  overflow: auto;
  height: 100px;
}
.scrollData {
  display: block;
  width: 100%;
  overflow: auto;
  height: 100px;
}

.fixed_header thead tr {
  display: block;
}

.fixed_header thead th {
  width: 125px;
}

.fixed_header th,
.fixed_header td {
  padding: 5px;
  text-align: center;
  width: 125px;
}
</style>
