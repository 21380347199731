<template>
  <MainAnalytics />
</template>

<script>
// @ is an alias to /src

import MainAnalytics from '@/components/MainAnalytics.vue';

export default {
  name: 'Statistics',
  components: {
    MainAnalytics
  },
  data() {
    return {
      projets: null
    };
  }
};
</script>
