<template>
  <div class="flex flex-col flex-wrap flex-grow">
    <app-breadcrumbs :key="componentKey" class="breadcrumb">
      <li slot-scope="{ to, label, utils }" class="bg-blue-normal">
        <router-link :to="to" exact :itemprop="utils && utils.itemprop">
          {{ label }}
        </router-link>
      </li>

      <a slot="current" slot-scope="{ label }" class="arrows">{{ label }}</a>
    </app-breadcrumbs>
    <PotreeContainer ref="potreecontainer" :type-potree="1" :scene2="sceneFusion" />
  </div>
</template>

<script>
// @ is an alias to /src

import PotreeContainer from '@/components/PotreeContainer.vue';
import gql from 'graphql-tag';
// import router from '@/router'
export default {
  name: 'PotreeView',

  components: {
    PotreeContainer
  },
  // props: ['id3'],
  props: {
    'id3': { type: String, default: '' }
  },
  breadcrumb: {
    parent: {
      name: 'scenes',
      params: {
        id: '2'
      }
    }
  },

  data() {
    return {
      componentKey2: 0,
      componentKey: 0
    };
  },
  watch: {
    scene: function () {
      console.log('scend modified.');
    }
  },
  methods: {
    forceRerender() {
      this.componentKey += 1;
    },
    forceRerender2() {
      this.componentKey2 += 1;
    }
  },
  apollo: {
    $client: 'apolloProjectClient',
    $subscribe: {
      onSceneSave: {
        query: gql`
          subscription {
            onSceneSave {
              scene {
                id
              }
            }
          }
        `,
        result() {
          this.$apollo.queries.sceneFusion.refetch();
        }
      }
    },
    sceneFusion: {
      // gql query
      query: gql`
        query getScenes($id: Int!) {
          sceneFusion(id: $id) {
            id
            geom
            nom
            etatProjet
            projectPath
            potreePath
            projectState
            projet {
              id
              nom
            }
            sceneSet {
              id
              nom
            }
          }
        }
      `,
      fetchPolicy: 'no-cache',
      variables() {
        // Use vue reactive properties here
        return {
          id: parseInt(this.id3)
        };
      },
      // Optional result hook
      result({ data }) {
        // this.id = data.scene.projet.id;
        this.$route.meta.breadcrumb.label = 'Fusion Projet ' + data.sceneFusion.nom;
        this.$route.meta.breadcrumb.parent.params.id = data.sceneFusion.projet.id;
        this.$router.options.routes[2].meta.breadcrumb.label = data.sceneFusion.projet.nom;
        // this.$router.options.routes[2].meta.breadcrumb.parent.params.id= data.scene.projet.id
        console.log(this.$router);

        this.forceRerender();
        this.forceRerender2();
        console.log(data.sceneFusion.projectState);
        this.$refs.potreecontainer.loadMeasurementsData(data.sceneFusion.projectState);
      }
      // Static parameters
    }
  }
};
</script>
<style>
/* 
.breadcrumb { 
  list-style: none; 
  overflow: hidden; 
  font: 18px Helvetica, Arial, Sans-Serif;
  margin: 5px;
  padding: 0;
}
.breadcrumb li { 
  float: left; 
}
.breadcrumb li a {
  color: white;
  text-decoration: none; 
  padding: 10px 0 10px 55px;
  background: rgba(59, 130, 246, 1); 
  background: rgba(59, 130, 246, 1); 
  position: relative; 
  display: block;
  float: left;
}
.breadcrumb li a:after { 
  content: " "; 
  display: block; 
  width: 0; 
  height: 0;
  border-top: 50px solid transparent;           
  border-bottom: 50px solid transparent;
  border-left: 30px solid rgba(59, 130, 246, 1);
  position: absolute;
  top: 50%;
  margin-top: -50px; 
  left: 100%;
  z-index: 2; 
}	
.breadcrumb li a:before { 
  content: " "; 
  display: block; 
  width: 0; 
  height: 0;
  border-top: 50px solid transparent;           
  border-bottom: 50px solid transparent;
  border-left: 30px solid white;
  position: absolute;
  top: 50%;
  margin-top: -50px; 
  margin-left: 1px;
  left: 100%;
  z-index: 1; 
}	
.breadcrumb li:first-child a {
  padding-left: 15px;
}
.breadcrumb li:last-child a {
  background: rgba(59, 130, 246, 1) !important;
  color: white;
  pointer-events: none;
  cursor: default;
}
.breadcrumb li:last-child a:after { border-top: 50px solid transparent;          
  border-bottom: 50px solid transparent;
  border-left: 30px solid rgba(59, 130, 246, 1);  }
.breadcrumb li a:hover { background: rgb(144, 187, 255);; }
.breadcrumb li a:hover:after { border-left-color: rgb(144, 187, 255) !important; } */
</style>
