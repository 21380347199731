<template>
  <div>
    <div
      v-if="showModal"
      class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex"
    >
      <!--content-->
      <div class="bg-grey-lighter min-h-screen min-w-1/2 flex flex-col">
        <div class="container min-w-full max-w-sm mx-auto flex-1 flex flex-col items-center justify-center px-2">
          <div class="bg-white px-6 py-8 rounded shadow-md text-black w-full h-full">
            <h1 class="mb-8 text-3xl text-center">
              Liste des Projets
            </h1>

            <t-table :headers="getHeader" :data="paginatedProjects">
              <template v-slot:thead="props">
                <thead :class="props.theadClass">
                  <tr :class="props.trClass">
                    <template v-for="(item, index) in props.data" :class="[props.thClass]">
                      <th :key="index" :class="{ hidden: item.text == 'id' }">
                        {{ item.text }}
                      </th>
                    </template>
                  </tr>
                </thead>
              </template>
              <template slot="row" slot-scope="props">
                <tr :class="[props.trClass, props.rowIndex % 2 === 0 ? 'bg-gray-100' : '']" class="text-center">
                  <td class="hidden">
                    {{ props.row.id }}
                  </td>
                  <td v-if="roleName != 'user'">
                    {{ props.row.refIntern }}
                  </td>
                  <td>
                    {{ props.row.nom }}
                  </td>
                  <td>
                    {{ calculateDateFin(props.row.dateDepot) }}
                  </td>
                  <td>
                    {{ props.row.nbAlignedImages }}
                  </td>
                  <td>
                    {{ props.row.nbTotalImages }}
                  </td>
                  <td>
                    {{ props.row.ErreurMoyEchelle.toFixed(3) }}
                  </td>
                  <td :class="['text-center']">
                    <router-link :to="`/projet/${props.row.id}`">
                      <svg height="32" viewBox="0 0 512 512" width="32" xmlns="http://www.w3.org/2000/svg">
                        <g id="Solid">
                          <path
                            d="m260.52 329.539a24 24 0 0 0 33.941 33.941l90.51-90.51a24 24 0 0 0 0-33.941l-90.51-90.509a24 24 0 0 0 -33.941 0 24 24 0 0 0 0 33.941l49.539 49.539h-262.059a24 24 0 0 0 -24 24 24 24 0 0 0 24 24h262.059z"
                          />
                          <path
                            d="m448 24h-224a40 40 0 0 0 -40 40v32a24 24 0 0 0 48 0v-24h208v368h-208v-24a24 24 0 0 0 -48 0v32a40 40 0 0 0 40 40h224a40 40 0 0 0 40-40v-384a40 40 0 0 0 -40-40z"
                          />
                        </g>
                      </svg>
                    </router-link>
                  </td>
                </tr>
              </template>
              <template slot="tfoot" slot-scope="{ tfootClass, trClass, tdClass, renderResponsive }">
                <tfoot :class="tfootClass">
                  <tr :class="trClass">
                    <td :class="tdClass" :colspan="renderResponsive ? 2 : 7">
                      <t-pagination
                        :value="1"
                        :hide-prev-next-controls="renderResponsive"
                        :total-items="projectData.length"
                        :per-page="10"
                        :class="{
                          'ml-auto': !renderResponsive,
                          'mx-auto': renderResponsive
                        }"
                        @change="getPage"
                      />
                    </td>
                  </tr>
                </tfoot>
              </template>
            </t-table>
            <button
              class="w-full text-center py-3 rounded bg-raphal-valider text-white hover:bg-gray-500 focus:outline-none my-1"
              @click="closeForme"
            >
              Fermer
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showModal" class="opacity-25 fixed inset-0 z-40 bg-black" />
  </div>
</template>

<script>
import gql from 'graphql-tag';
import { apolloProjectClient } from '../vue-apollo';
import { mapGetters } from 'vuex';

export default {
  name: 'ModalListProjects',
  components: {},
  props: [],
  data() {
    return {
      type_traitement: '0',
      projectData: [],
      lenUsers: 0,
      showmodal: false,
      numPage: 1,
      showModal: false,
      header: []
    };
  },
  computed: {
    ...mapGetters({
      isAuth: 'Auth/isAuth',
      user: 'Auth/user',
      roleName: 'Auth/roleUser'
    }),
    paginatedProjects() {
      if (this.projectData == null) {
        return [];
      }
      var slicedProjects = this.projectData.slice(10 * (this.numPage - 1), 10 * this.numPage - 1);
      return slicedProjects;
    },
    getHeader() {
      if (this.roleName == 'user') {
        return ['id', 'Nom Projet', 'Date dépot', 'Images alignées', 'Images total', 'Moyenne Echelle', 'Acceder'];
      } else {
        return [
          'id',
          'Ref interne',
          'Nom Projet',
          'Date dépot',
          'Images alignées',
          'Images total',
          'Moyenne Echelle',
          'Acceder'
        ];
      }
    }
  },
  methods: {
    getPage(num) {
      console.log(num);
      this.numPage = num;
    },
    calculateDateFin: function (dateString) {
      console.log(dateString.split('T')[0]);
      let date = new Date(dateString);

      return date.toLocaleString();
    },
    toggleModal: function (etat) {
      this.fetchOptions(etat);
      this.showModal = !this.showModal;
      this.type_traitement = etat;
      this.numPage = 1;
    },
    async fetchOptions(q) {
      try {
        let { data: data } = await apolloProjectClient.query({
          query: gql`
            query ($searchQyery: String!) {
              SearchProjectsByTreatement(searchQyery: $searchQyery) {
                id
                nom
                refIntern
                dateDepot
                nbAlignedImages
                nbTotalImages
                ErreurMoyEchelle
              }
            }
          `,

          fetchPolicy: 'network-only',
          variables: {
            searchQyery: q
          }
        });

        this.projectData = data.SearchProjectsByTreatement;
      } catch (err) {
        console.log(err);
        // eslint-disable-next-line
        Toast.fire({
          icon: 'error',
          title: err.nonFieldErrors[0].message
        });
      }
    },
    cleanForme: function () {
      this.type_traitement = null;
    },
    closeForme: function () {
      this.showModal = false;
      this.cleanForme();
    },
    affectToProject: function () {
      let query = gql`
        mutation affectUserToProject($idProject: Int!, $idUser: Int!) {
          affectUserToProject(idProject: $idProject, idUser: $idUser) {
            success
          }
        }
      `;

      this.$apollo
        .mutate({
          mutation: query,
          client: 'apolloProjectClient',

          variables: {
            idUser: this.userRow.id,
            idProject: this.projects
          }
        })
        .then((data) => {
          // Result
          if (data.data.affectUserToProject) {
            if (data.data.affectUserToProject.success == true) {
              // eslint-disable-next-line
              Toast.fire({
                icon: 'success',
                title: 'Opération effectuée avec succès !'
              });
              this.showModal = false;
              this.cleanForme();
            } else {
              let listErrors = 'Erreur\n';
              let dictErrors = [
                'email',
                'username',
                'password1',
                'password2',
                'firstName',
                'lastName',
                'nonFieldErrors'
              ];
              for (let error of dictErrors) {
                if (data.data.affectUserToProject.errors[error]) {
                  for (let err of data.data.register.errors[error]) {
                    listErrors += err.message + '\n';
                  }
                }
              }
              // eslint-disable-next-line
              Toast.fire({
                icon: 'error',
                title: listErrors
              });
            }
          } else {
            // eslint-disable-next-line
            Toast.fire({
              icon: 'error',
              title: 'Erreur !'
            });
          }
        })
        .catch((error) => {
          // Error
          console.error(error);
          // We restore the initial user input
        });
    }
  }
};
</script>
