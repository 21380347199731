<template>
  <div>    
    <div
      v-if="showModal"
      class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex"
    >
      <!--content-->
      <div class="bg-grey-lighter min-h-screen  flex flex-col">
        <div class="container w-full mx-auto flex-1 flex flex-col items-center justify-center px-2">
          <div class="bg-white  px-6 py-8 rounded shadow-md text-black w-full">
            <h1 class="mb-8 text-3xl text-center">
              Envoyer Projet
            </h1>            
            <ImageSelect class=" overflow-y-scroll max-h-96"
                         :data-images="dataImages"
                         :h="'150px'"
                         :w="'150px'"
                         :is-multiple="true"
                         :use-label="true"
                         :selected-images="listSelectedImages"
                         @onselectmultipleimage="onSelectMultipleImage"
            />
            <div class="w-full flex items-center justify-center">
              <button 
                class="w-1/3 text-center py-3 mx-2 rounded  bg-raphal-valider text-white hover:bg-gray-500 focus:outline-none my-1"
                @click="closeDialog"
              >
                Annuler
              </button>
              <button
                class="w-1/3 text-center py-3 mx-2 rounded bg-raphal-total text-white hover:bg-raphal-termine focus:outline-none my-1"
                @click="sendImagesList"
              >
                OK
              </button>                
            </div>
          </div>
        </div>
      </div>      
    </div>
    <div v-if="showModal" class="opacity-25 fixed inset-0 z-40 bg-black" />
  </div>
</template>

<script>
import gql from "graphql-tag";
import { apolloClient } from "../vue-apollo";
import { mapGetters } from "vuex";
import ImageSelect from "./ImageSelect.vue";

export default {
  name: "ModalUpdateProject",
  components: {
    ImageSelect
  },
  props: [],
  data() {
    return {
      showModal: false,
      userId: '',
      dataImages: [],
      listSelectedImages: [],
      idProject: null,
    };
  },
  computed: {
    ...mapGetters({
      isAuth: "Auth/isAuth",
      user: "Auth/user",
      roleName: "Auth/roleUser"
    })
  },
  methods: {    
    toggleModal (dataImages, idProject) {
      this.showModal = !this.showModal;
      this.dataImages = dataImages.allImages.map((img) => {
        let imgName = img.substring(img.lastIndexOf('/')+1);
        return {
          id: parseInt(imgName.split("_")[1].replaceAll(".jpg", "")),
          src: img,
          alt: imgName
        }        
      });
      // this.listSelectedImages=this.dataImages
      this.listSelectedImages = dataImages.selectedImages.map((img) => {
        let imgName= img.substring(img.lastIndexOf('/')+1)
        return {
          id: parseInt(imgName.split("_")[1].replaceAll(".jpg", "")),
          src: img,
          alt: imgName
        }        
      });
      this.idProject = idProject
    },
    onSelectMultipleImage (lst) {
      // this.dataImages=lst;
      this.listSelectedImages = lst        
    },
    async fetchOptions (q) {
      try {
        let all = false;
        if (this.roleName=='admin') {
          all = true;
        }
        if (q == null) {
          q = '';
        }
        let {
          data: data
        } = await apolloClient.query({
         query: gql`query($role: String!, $searchQyery: String!, $all: Boolean!) {
            searchUser(role: $role, searchQyery: $searchQyery, all: $all) {
              id
              username
              email
            }
          }`,
          variables: {
            role: "user", 
            searchQyery: q,
            all: all
          }
        });
       
        return { 
          results: data.searchUser 
        }

      } catch (err) {
        console.log(err);
        // eslint-disable-next-line
        Toast.fire({
          icon: "error",
          title: err.nonFieldErrors[0].message
        });
      }
       
    },
    cleanForm () {
      this.userId = '';
      this.files = null;
    },
    closeDialog () {
      this.showModal = false; 
      this.cleanForm();
    },
    sendImagesList () {
      let query = gql`
        mutation filterProjectImages($idProject: Int!, $listFilteredImages: JSONString!) {
          filterProjectImages(idProject: $idProject, listFilteredImages: $listFilteredImages) {
            success, errors
          }
        }
      `;
      let vars = {
        idProject: this.idProject, 
        listFilteredImages: JSON.stringify(this.listSelectedImages.map((img) => {
          let src = img.src.split("/");
          return src[src.length-1];
        }))
      }
      this.$apollo.mutate({
        client: "apolloProjectClient",
        mutation: query, 
        variables: vars,
      }).then((data) => {
        if (data.data[Object.keys(data.data)[0]].success === true) {
          // eslint-disable-next-line
            Toast.fire({
            icon: "success",
            title: "Opération effectuée avec succès !"
          });
          this.closeDialog();          
        } else {
          // eslint-disable-next-line
          Toast.fire({
            icon: "error",
            title: "Fichier Invalide"
          });
        }
      }).catch(() => {
        // eslint-disable-next-line
        Toast.fire({
          icon: "error",
          title: "erreur !"
        });
      })
    }
  }
};
</script>
