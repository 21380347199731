<template>
  <!-- eslint-disable-next-line vue/max-attributes-per-line -->
  <v-chart :style="{ height: '67px' }" :option="option" autoresize />
</template>

<script>
import { use } from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { PieChart } from 'echarts/charts';
import { TitleComponent, TooltipComponent, LegendComponent, ToolboxComponent } from 'echarts/components';
import VChart, { THEME_KEY } from 'vue-echarts';

use([CanvasRenderer, PieChart, TitleComponent, TooltipComponent, LegendComponent, ToolboxComponent]);

export default {
  name: 'EchartPie',
  components: {
    VChart
  },
  // props: ['chartData', 'chartTitle', 'heightPie'],
  props: {
    chartData: { type: Array, default: null },
    chartTitle: { type: String, default: '' },
    heightPie: { type: Number, default: 70 }
  },
  provide: {
    [THEME_KEY]: 'vintage'
  },
  data() {
    return {
      height: this.heightPie,
      option: {
        title: {
          text: (this.chartData && (this.chartData.length > 0)) ? this.chartData[1].value + '%' : '',
          left: 'center',
          top: 'center',
          textStyle: {
            fontSize: 12
          }
        },
        tooltip: {
          show: false,
          color: '#777777'
        },
        labelLine: {
          show: false
        },
        color: ['#f7f7f7', '#777777', '#99cccc', '#37A8DF', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc'],
        toolbox: {
          show: false
        },
        series: [
          {
            name: 'Access From',
            type: 'pie',
            radius: ['75%', '100%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: '#fff',
              borderWidth: 2
            },
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              scale: false,
              disabled: true
            },
            labelLine: {
              show: false
            },
            data: this.chartData
          }
        ]
      }
    };
  }
};
</script>

<style scoped>
.chart {
  height: 50px;
}
</style>
