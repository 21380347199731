<template>
  <div>
    <div
      v-if="showmodal"
      class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex"
    >
      <div class="bg-grey-lighter min-h-screen min-w-1/2 flex flex-col">
        <div class="container min-w-full max-w-sm mx-auto flex-1 flex flex-col items-center justify-center px-2">
          <div class="bg-white px-6 py-8 rounded shadow-md text-black w-full h-full">
            <h1 class="mb-2 text-3xl text-center">
              Ajouter des photos
            </h1>
            
            <div v-if="!isMaxPhotosReached" class="w-full grid gap-5">
              <div class="w-full py-9 bg-gray-50 rounded-2xl border border-gray-300 border-dashed"
                   @dragover.prevent="handleDragOver" 
                   @drop.prevent="handleDrop"
              >
                <div class="grid gap-3">
                  <div>
                    <div class="w-12 h-12 mx-auto bg-white rounded-full text-lg border-2 flex items-center" :class="borderPhotos">
                      <span class="text-center w-full">
                        <svg
                          height="22pt"
                          width="22pt"
                          class="w-full fill-current p-1"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 484 340.25"
                        >
                          <g id="Calque_2" data-name="Calque 2">
                            <path
                              d="M484.88,85.17H29.12A14.16,14.16,0,0,0,15,99.29v312a14.16,14.16,0,0,0,14.12,14.12H484.88A14.16,14.16,0,0,0,499,411.3v-312A14.16,14.16,0,0,0,484.88,85.17ZM377.3,113.84a45.44,45.44,0,1,1-45.43,45.44A45.43,45.43,0,0,1,377.3,113.84ZM314.93,404.09l-242-.44.1,0-2.1.37,121-212.51L292.8,365.84,353.7,258.9l0-.15.06.11.23-.4.23,1.2,83.64,144.58L314.89,404Z"
                              transform="translate(-15 -85.17)"
                            />
                          </g>
                        </svg>
                      </span>
                    </div>
                    <h2 class="text-center text-gray-400   text-xs font-light leading-4">
                      PNG, JPG, max. 5MB
                    </h2>
                  </div>
                  <div class="grid gap-2">
                    <h4 class="text-center text-gray-900 text-sm font-medium leading-snug">
                      Déposez vos photos ici ou
                    </h4>
                    <div class="flex items-center justify-center">
                      <label>
                        <input type="file" accept=".jpg, .png" multiple hidden @change="handleFileChange">
                        <div
                          class="w-28 text-base flex flex-col border border-black-2 items-center text-black-2 rounded-md hover:bg-raphal-valider hover:text-white"
                          rounded
                        >
                          Choisir photos
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="scrollable-file-list">
              <div v-for="(f, i) in file" :key="i" class="text-sm text-gray-400 text-center font-semibold">
                <span v-if="f.size > maxSize" class="text-red-600">{{ f.name }} (> 5MB, ne sera pas ajouté)</span>
                <span v-else-if="!fileValidity[i]" class="text-red-600">
                  {{ f.name }} (Fichier invalide, ne sera pas ajouté)
                </span>
                <span v-else>{{ f.name }}</span>
              </div>
            </div>

            <div class="flex flex-row gap-2">
              <button
                v-if="isUpload"
                class="w-full text-center py-3 rounded bg-gray-400 focus:outline-none my-1"
                disabled
              >
                Ajout en cours ...
              </button>
              <button
                v-if="!isUpload && !isMaxPhotosReached"
                class="w-full text-center py-3 rounded bg-raphal-valider text-white hover:bg-gray-500 focus:outline-none my-1"
                @click="uploadFile"
              >
                Ajouter
              </button>
              <button
                class="w-full text-center py-3 rounded bg-raphal-valider text-white hover:bg-gray-500 focus:outline-none my-1"
                @click="closeModal"
              >
                Fermer
              </button>
            </div>
            
            <span class="text-red-600 text-xs">{{ errorMsg }}</span>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showmodal" class="opacity-25 fixed inset-0 z-40 bg-black" />
  </div>
</template>

<script>
import { apolloProjectClient } from '../vue-apollo';
import gql from 'graphql-tag';

export default {
  name: 'ModalUploadFile',
  components: {},
  props: {
    showmodal: { type: Boolean, default: false },
    projectId: {type: String, default: ''},
  },
  data() {
    return {
      file: null,
      maxSize: 5 * 1024 * 1024,
      errorMsg: '',
      fileValidity: {},
      isUpload: false,
      isMaxPhotosReached: false,
      countOtherPhotos: 0
    };
  },
  watch : {
    showmodal: function () {
      if(this.showmodal) {
        this.verifyPhotoCount()
      }
    }
  },
  methods: {
    borderPhotos() {
      return {
        'border-raphal-termine text-raphal-termine': this.etatReceptionPhotos == 1,
        'border-red-600 text-red-600': this.etatReceptionPhotos == 0
      };
    },
    async uploadFile() {
      if (!this.file) {
        this.errorMsg = 'Veuillez choisir une photo !';
        return;
      }

      this.errorMsg = ''
      const query = gql`
          mutation uploadImages($file: [Upload]!, $projectId: String!) { 
            uploadImages(file: $file, projectId: $projectId) { success, message }
          }`;

      this.isUpload = true
      this.$apollo.mutate({
        client: "apolloProjectClient",
        mutation:query, 
        variables: { file: this.file, projectId: this.projectId }
      }).then((data) => {
        const res = data.data[Object.keys(data.data)[0]];
        if (res.success==true) {
          // eslint-disable-next-line
            Toast.fire({
              icon: "success",
              title: res.message
            });
            this.closeModal();
            this.$parent.$parent.isProjectUpdated = true;
        } else {
          this.errorMsg = res.message;
        }
        this.isUpload = false
      })
    },
    async verifyPhotoCount() {
      let { data } = await apolloProjectClient.query({
        query: gql`
          query countPhotoOthers($projectId: String!) {
            countPhotoOthers(projectId: $projectId)
          }
        `,
        variables: { projectId: this.projectId },
        fetchPolicy: 'network-only'
      });

      this.countOtherPhotos = data.countPhotoOthers;

      this.isMaxPhotosReached = data.countPhotoOthers >= 10 ? true : false;

      this.errorMsg = this.isMaxPhotosReached ? 'Vous avez atteint le nombre maximum de photos autorisés' : '';
    },
    async handleFileChange(event) {
      this.file = event.target.files;
      this.runValidateFiles()
    },
    handleDragOver(event) {
      event.preventDefault();
    },
    async handleDrop(event) {
      event.preventDefault();
      this.file = event.dataTransfer.files;
      this.runValidateFiles()
    },
    async runValidateFiles() {
      const files = Array.from(this.file);
      this.errorMsg = '';

      const countTotal = this.countOtherPhotos + files.length

      if (files.length > 10) {
        this.errorMsg = 'Vous ne pouvez ajouter que 10 photos tout au plus !';
        this.file = null
      } else if (countTotal > 10) {
        this.errorMsg = 'Vous avez atteint le nombre maximal de photos autorisés !';
        this.file = null
      }
      else {
        await this.validateFiles(files);
      }
    },
    async validateFiles(files) {
      const validity = {};
      await Promise.all(files.map(async (file, index) => {
        const isValid = await this.isValidImage(file);
        this.$set(validity, index, isValid);
      }));
      this.fileValidity = validity;
    },
    closeModal() {
      this.file = null
      this.errorMsg = ''
      this.$emit('update:showmodal', false);
    },
    isValidImage(file) {
      return new Promise((resolve, reject) => {
        const validSignatures = {
          png: "89504e47",
          jpg: ["ffd8ffe0", "ffd8ffe1", "ffd8ffe2", "ffd8ffe3", "ffd8ffe8"]
        };

        const fileReader = new FileReader();

        fileReader.onload = function (e) {
          const arr = new Uint8Array(e.target.result).subarray(0, 4);
          let header = "";
          for (let i = 0; i < arr.length; i++) {
            header += arr[i].toString(16);
          }

          const isValid = header === validSignatures.png || 
                          validSignatures.jpg.includes(header);

          resolve(isValid);
        };

        fileReader.onerror = function () {
          reject(new Error("Failed to read file"));
        };

        fileReader.readAsArrayBuffer(file);
      });
    }
  }
};
</script>

<style scoped>
  .scrollable-file-list {
    max-height: 20vh;
    overflow: auto;
  }
</style>
