var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"h-full w-full flex-grow"},[_c('modalMatos',{ref:"modalMatosForm",attrs:{"refresh-table":_vm.refreshTable,"show-modal-sync":"showmodal"}}),_c('t-table',{staticClass:"h-full",attrs:{"headers":_vm.getHeader,"data":_vm.paginatedProjects},scopedSlots:_vm._u([{key:"thead",fn:function(props){return [_c('thead',{class:props.theadClass},[_c('tr',{class:props.trClass},_vm._l((props.data),function(item,index){
var _obj;
return _c('th',{key:index,class:( _obj = { hidden: item.text == 'id' }, _obj[props.thClass] = true, _obj )},[_vm._v(" "+_vm._s(item.text)+" ")])}),0)])]}},{key:"row",fn:function(props){return [_c('tr',{staticClass:"text-center",class:[props.trClass, props.rowIndex % 2 === 0 ? 'bg-gray-100' : '']},[_c('td',{staticClass:"hidden"},[_vm._v(" "+_vm._s(props.row.id)+" ")]),_c('td',[_vm._v(" "+_vm._s(props.row.identifiant)+" ")]),_c('td',[_vm._v(" "+_vm._s(props.row.gpsDevice.model)+" ")]),_c('td',[_vm._v(" "+_vm._s(props.row.gpsDevice.serialNumber)+" ")]),_c('td',[_vm._v(" "+_vm._s(props.row.smartPhone.model)+" ")]),_c('td',[_vm._v(" "+_vm._s(props.row.smartPhone.serialNumber)+" ")]),_c('td',[_vm._v(" "+_vm._s(props.row.prop.username)+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.timestamp(new Date(props.row.creationDate)))+" ")]),_c('td',{class:['text-center']},[_c('t-dropdown',{attrs:{"variant":"raphal"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return _c('div',{},[_c('button',{staticClass:"block w-full px-4 py-2 text-left text-gray-800 hover:text-white hover:bg-gray-1",on:{"click":[function($event){$event.stopPropagation();return _vm.editRow(props.row)},hide]}},[_vm._v(" Modifier ")]),_c('button',{staticClass:"block w-full px-4 py-2 text-left text-gray-800 hover:text-white hover:bg-gray-1",on:{"click":[function($event){$event.stopPropagation();return _vm.deleteMatos(props.row)},hide]}},[_vm._v(" Supprimer ")])])}}],null,true)},[_c('template',{slot:"button"},[_c('svg',{staticClass:"text-gray-1 fill-current svg-icon svg-fill",staticStyle:{"width":"20px"},attrs:{"version":"1.1","viewBox":"0 0 16 16","heigth":"20"}},[_c('path',{attrs:{"pid":"0","d":"M13 7a2 2 0 1 1 .001 3.999A2 2 0 0 1 13 7zM8 7a2 2 0 1 1 .001 3.999A2 2 0 0 1 8 7zM3 7a2 2 0 1 1 .001 3.999A2 2 0 0 1 3 7z"}})])])],2)],1)])]}},{key:"tfoot",fn:function(ref){
var tfootClass = ref.tfootClass;
var trClass = ref.trClass;
var tdClass = ref.tdClass;
var renderResponsive = ref.renderResponsive;
return [_c('tfoot',{class:tfootClass},[_c('tr',{class:trClass},[_c('td',{class:tdClass,attrs:{"colspan":renderResponsive ? 2 : 4}},[_c('t-pagination',{class:{
                'ml-auto': !renderResponsive,
                'mx-auto': renderResponsive
              },attrs:{"hide-prev-next-controls":renderResponsive,"total-items":_vm.lenMatos,"per-page":_vm.nbrows},on:{"change":_vm.getPage}})],1)])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }