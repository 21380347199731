<template>
  <div>
    <div
      v-if="showModal"
      class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex"
    >
      <!--content-->
      <div class="bg-grey-lighter min-h-screen min-w-1/2 flex flex-col">
        <div class="container max-w-sm mx-auto flex-1 flex flex-col items-center justify-center px-2">
          <div class="bg-white px-6 py-8 rounded shadow-md text-black w-full">
            <h1 class="mb-8 text-3xl text-center">
              Corrections
            </h1>
                    
            <div class="flex items-center justify-center m-2">
              <span class="font-bold w-1/4">
                X (m) :
              </span>
              <input v-model="x" class="shadow appearance-none border text-xl border-gray-1 rounded w-full py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" step="0.01" type="number">
            </div>
            <div class="flex items-center justify-center m-2">
              <span class="font-bold w-1/4">
                Y (m) :
              </span>
              <input v-model="y" class="shadow appearance-none border text-xl border-gray-1 rounded w-full py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" step="0.01" type="number">
            </div>
            <div class="flex items-center justify-center m-2">
              <span class="font-bold w-1/4">
                Z (m) :
              </span>
              <input v-model="z" class="shadow appearance-none border text-xl border-gray-1 rounded w-full py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" step="0.01" type="number">
            </div>
                    
            <button
              class="w-full text-center py-3 rounded bg-raphal-total text-white hover:bg-raphal-termine focus:outline-none my-1"
              @click="updateShift"
            >
              Valider
            </button>
            <button 
              class="w-full text-center py-3 rounded  bg-raphal-valider text-white hover:bg-gray-500 focus:outline-none my-1"
              @click="closeDialog"
            >
              Fermer
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showModal" class="opacity-25 fixed inset-0 z-40 bg-black" />
  </div>
</template>

<script>
import gql from "graphql-tag";
import { apolloClient } from "../vue-apollo";
import { mapGetters } from "vuex";

export default {
  name: "ModalShiftProject",
  components: {},
  props: [],
  data () {
    return {
      showModal: false,
      x: 0,
      y: 0,
      z: 0,
      idProject: null      
    };
  },
  computed: {
    ...mapGetters({
      isAuth: "Auth/isAuth",
      user: "Auth/user",
      roleName: "Auth/roleUser"
    }),
    /* proj4() {
      if (window.proj4 && !('EPSG:2154' in window.proj4.defs)) {
        window.proj4.defs("EPSG:2154", "+proj=lcc +lat_0=46.5 +lon_0=3 +lat_1=49 +lat_2=44 +x_0=700000 +y_0=6600000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs");
      }
      return window.proj4;
    } */
  },
  methods: {
    calculateDateFin: function (dateString) {
      let date = new Date(dateString);      
      return date.toLocaleString();
    },
    toggleModal: function (idProject) {
      this.showModal = !this.showModal;
      this.idProject = idProject;
    },
    async fetchOptions (q) {
      try {
        let all = false;
        if (this.roleName === 'admin') {
          all = true;
        }
        if (q === null) {
          q = '';
        }
        let {
          data: data
        } = await apolloClient.query({
          query: gql`query($role: String!, $searchQyery: String!) {
            searchUser(role:$role,searchQyery:$searchQyery){
              id
              username
              email
            }
          }`,
          variables: {
            role: "user", 
            searchQyery: q,
            all: all
          }
        });
        return {
          results: data.searchUser
        }
      } catch (err) {
        // console.log(err);
        // eslint-disable-next-line
        Toast.fire({
          icon: "error",
          title: err.nonFieldErrors[0].message
        });
      }
    },
    clearForm: function() {
      this.userRow = null;      
      this.idProject = null;
      this.x = 0;
      this.y = 0;
      this.z = 0;
    },
    closeDialog: function() {
      this.showModal = false; 
      this.clearForm();
    },
    updateShift: function () {
      let query = gql`
        mutation shiftProject($idProject:Int!, $x:Float!, $y:Float!, $z:Float!) {
          shiftProject(idProject:$idProject, x:$x, y:$y, z:$z) {
            success,
            errors
          }
        }
      `;
      let [x, y, z] = [this.x, this.y, this.z].map(parseFloat);
      if (x === 0 && y === 0 && z === 0) {
        // eslint-disable-next-line
        Toast.fire({
          icon: "error",
          title: "Veuillez saisir au moins l'une des coordonnées!"
        });
      } else {
        // [x, y, z] = this.proj4('EPSG:2154', 'EPSG:4326', [x, y, z]).map(w => parseFloat(w.toFixed(6)))
        let vars = {
          idProject: this.idProject,
          x,
          y,
          z
        }

        this.$apollo.mutate({
          client: "apolloProjectClient",
          mutation: query,
          variables: vars,
        }).then((data) => {
          // Result
          if (data.data[Object.keys(data.data)[0]].success==true) {
            // eslint-disable-next-line
            Toast.fire({
              icon: "success",
              title: "Opération effectuée avec succès !"
            });
            this.closeDialog()
          } else {
            // eslint-disable-next-line
            Toast.fire({
              icon: "error",
              title: "Fichier Invalide"
            });
          }
        }).catch((error) => {
        // Error
          console.error(error)
        // We restore the initial user input
        })
      }
    }
  }
};
</script>