<template>
  <div>
    <viewer
      v-if="images && images.length"
      ref="viewerImage"
      :images="images"
      :options="options"
      class="viewer"
      @inited="inited"
    >
      <img v-for="src in images" :key="src" :src="src" class="image">
    </viewer>
  </div>
</template>

<script>
import Vue from 'vue';
import Viewer from 'v-viewer';
import 'viewerjs/dist/viewer.css';
Vue.use(Viewer);
export default {
  data() {
    return {
      index: 0,
      images: [],
      options: {
        interval: 1000,
        loop: false,
        rotatable: false, 
        scalable: false, 
        transition: false,
        toolbar: {
          zoomIn: true,
          zoomOut: true,
          oneToOne: true,
          prev: true,
          play: true,
          next: true
        }
      }
    };
  },
  methods: {
    inited(viewer) {
      this.$viewerImage = viewer;
    },
    view(index) {
      this.index = index;
      this.$viewerImage.view(0);
      //   this.$viewerImage.show();
    },
    setImages(images) {
      this.images = images;
    },
    show(images, index = 0) {
      if (this.images === images) {
        this.view(index);
        return;
      }
      this.index = index;
      this.setImages(images);
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.image {
  display: none;
}
</style>
<style>
.viewer-loading > img {
  display: none; /* hide big images when it is loading */
}
</style>
