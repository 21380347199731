<template>
  <input type="file" @change="upload">
</template>

<script>
import gql from 'graphql-tag';

export default {
  props: {
    typeUpload: { type: Number, default: 0 },
    utilisateur: { type: String, default: '' }
  },
  methods: {
    upload({ target: { files = [] } }) {
      if (!files.length) {
        return;
      }
      console.log(files[0]);
      let query = gql`
        mutation uploadFilee($file: Upload!, $idUser: String!) {
          uploadFile(file: $file, idUser: $idUser) {
            success
          }
        }
      `;
      if (this.typeUpload == 1) {
        query = gql`
          mutation uploadFilee($file: Upload!, $idUser: String!) {
            uploadFileUpdate(file: $file, idUser: $idUser) {
              success
            }
          }
        `;
      }
      let that_ = this;
      this.$apollo
        .mutate({
          client: 'apolloProjectClient',
          mutation: query,
          variables: {
            file: files[0],
            idUser: this.utilisateur
          },
          context: {
            hasUpload: true
          }
        })
        .then((data) => {
          // Result
          let success = false;
          if (that_.typeUpload == 1) {
            success = data.data.uploadFileUpdate.success;
          } else {
            success = data.data.uploadFile.success;
          }
          if (success == true) {
            // eslint-disable-next-line
            Toast.fire({
              icon: 'success',
              title: 'Opération effectuée avec succès !'
            });
          } else {
            // eslint-disable-next-line
            Toast.fire({
              icon: 'error',
              title: 'Fichier Invalide'
            });
          }
        })
        .catch(() => {
          // eslint-disable-next-line
          Toast.fire({
            icon: 'error',
            title: 'erreur !'
          });
        });
    }
  }
};
</script>
