<template>
  <div class="">
    <div class="bg-gray-200">
      <div class="md:mx-auto max-w-6xl px-6 py-12">
        <div class="text-center font-bold text-4xl" style="color: #242838">
          L'outil de mesure est déjà dans votre poche
        </div>
        <div class="flex relative justify-center items-center py-10">
          <div class="relative">
            <img src="../assets/landing_page.png">
            <a
              href="https://play.google.com/store/apps/details?id=com.geofit.raphal_gui"
              class="absolute transform ease-in-out duration-200 hover:scale-110"
              style="top: 85%; left: 7%; width: 12%"
            >
              <img src="../assets/google_play_store_badge_fr.png">
            </a>
            <div class="absolute" style="top: 9.7%; left: 33.7%; width: 54.9%; height: 67.2%">
              <!-- Project Header Component start -->
              <three-animation-component filename="Model_RAPHAL_Light" />
              <!-- Project Header Component end -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-gray-800">
      <div class="md:mx-auto max-w-6xl px-6 py-12">
        <div class="text-center text-gray-200 text-4xl py-6">
          Un service sans abonnement qui simplifie
          <br>
          vos relevés 3D et leurs traitements
        </div>
        <div class="flex flex-col items-center py-6">
          <a
            href="mailto:Contact RAPHAL<contact@raphal.fr>?subject=Demande de contact"
            class="transform ease-in-out duration-200 hover:scale-110 bg-white hover:bg-gray-100 font-semibold py-2 px-4 border border-gray-400 rounded shadow"
            style="color: #242838"
          >
            EN SAVOIR PLUS
          </a>
        </div>
      </div>
    </div>
    <div class="bg-gray-800">
      <div class="md:mx-auto max-w-6xl px-6 py-6">
        <div class="text-gray-500">
          © 2024 RAPHAL
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ThreeAnimationComponent from '@/components/ThreeAnimation.vue';

export default {
  components: {
    ThreeAnimationComponent
  },
  data() {
    return {};
  }
};
</script>

<style>
.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}
</style>
