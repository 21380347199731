<template>
  <MainComponent :fusion-list="[]" />
</template>

<script>
import MainComponent from '@/components/MainComponentPaginationAdmin.vue';

export default {
  name: 'AdminPagination',
  components: {
    MainComponent
  },
  data() {
    return {
      projets: null
    };
  },
  computed: {}
};
</script>
